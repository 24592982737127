import axios from '.'

export default class VideosService {
  /** 동영상 목록 조회 */
  static async getVideos(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
  }) {
    return await axios.get('/videos/', {
      params,
    })
  }

  /** 동영상 상세 조회 */
  static async getVideoDetails(videoId: string, searchKeyword: string) {
    return await axios.get(`/videos/${videoId}?keyword=${searchKeyword}`)
  }
}
