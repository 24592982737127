import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { audiosDataType, audiosType } from '@/types/audiosType'

interface initialStateType {
  loading: boolean
  error: unknown
  allVoicesDataCount: number
  audios: audiosDataType[] | null
  pagingNumber: number
}

const initialState: initialStateType = {
  loading: false,
  error: null,
  allVoicesDataCount: 0,
  audios: null,
  pagingNumber: 0,
}

const audios = createSlice({
  name: 'audios',
  initialState,
  reducers: {
    getAudios: (state, action: PayloadAction<audiosType>) => {
      const { meta, data } = action.payload

      state.allVoicesDataCount = meta.count
      state.audios = data
      // state.audios = data.filter((item) => item.file)
      state.pagingNumber = 1
    },
    getNextAudios: (
      state,
      action: PayloadAction<{ response: audiosType; pagingNumber: number }>,
    ) => {
      const {
        response: { data },
        pagingNumber,
      } = action.payload

      if (audios === null) return state

      state.audios = data
      state.pagingNumber = pagingNumber

      return state
    },
    reset: (state, action: PayloadAction<keyof initialStateType>) => {
      const id = action.payload

      if (id === 'audios') state[id] = null

      return state
    },
    error: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload

      return state
    },
  },
})

export const audiosActions = { ...audios.actions }

export default audios
