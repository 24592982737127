import { css } from '@emotion/react'
import styled from '@emotion/styled'

import iconDocDefault from '@/assets/images/icon_doc_default.svg'
import iconImgDefault from '@/assets/images/icon_img_default.svg'
import iconMovDefault from '@/assets/images/icon_movie_default.svg'
import iconVocDefault from '@/assets/images/icon_voice_default.svg'

import iconDocTab from '@/assets/images/icon_doc_tab.svg'
import iconImgTab from '@/assets/images/icon_img_tab.svg'
import iconMovTab from '@/assets/images/icon_movie_tab.svg'
import iconVocTab from '@/assets/images/icon_voice_tab.svg'

interface WrapperProps {
  to: string
  isCurrent: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  background-position: center center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 1px;
  ${({ isCurrent, to }) => {
    if (isCurrent) {
      switch (to) {
        case '/document':
          return css`
            background-image: url(${iconDocTab});
          `
        case '/image':
          return css`
            background-image: url(${iconImgTab});
          `
        case '/video':
          return css`
            background-image: url(${iconMovTab});
          `
        case '/audio':
          return css`
            background-image: url(${iconVocTab});
          `
      }
    } else {
      switch (to) {
        case '/document':
          return css`
            background-image: url(${iconDocDefault});
          `
        case '/image':
          return css`
            background-image: url(${iconImgDefault});
          `
        case '/video':
          return css`
            background-image: url(${iconMovDefault});
          `
        case '/audio':
          return css`
            background-image: url(${iconVocDefault});
          `
      }
    }
  }}
`
