import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { a11y } from '@/theme'
import search from '@/assets/images/search.svg'
import mic from '@/assets/images/mic.svg'
import arrow from '@/assets/images/arrow.svg'
import del from '@/assets/images/del.svg'

const icons = {
  search,
  mic,
  arrow,
  del,
}

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* margin-left: 20px; */

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const SearchBarSecondChildWrapper = styled.div`
  margin-top: 21px;
  margin-left: 33px;
  height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;

  span {
    margin-left: 20px;
  }

  .bar {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 100;
  }

  .title {
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors['blue']};
  }

  .word {
    ::before {
      content: '#';
    }
  }
`

export const SearchBar = styled.div`
  width: 460px;
  height: 50px;
  box-sizing: border-box;
  border: 2px solid #0f52ff;
  border-radius: 30px;
  background-color: white;
  position: relative;
`

// padding-left: 68px;
// padding-right: 26px;
export const SearchBarInner = styled.div`
  border-radius: 30px;
  min-height: 46px;
  box-sizing: border-box;
  padding-top: 11px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 1;
`

export const SearchBarInputGroup = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  width: 100%;
  align-items: center;

  input {
    width: 100%;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    border: 0;

    outline: none;
  }
`

interface InputButtonProps {
  title: 'del' | 'arrow' | 'search' | 'mic'
  isRotate180?: boolean
}

export const InputButton = styled.button<InputButtonProps>`
  background-image: url(${({ title }) => icons[title]});
  background-size: 26px 26px;
  background-position: center center;
  min-width: 26px;
  min-height: 26px;
  transform: rotateX(${({ isRotate180 }) => (isRotate180 ? '180deg' : '0')});
  transition: all 0.2s ease-in-out;
  border-radius: 5px;

  :focus {
    outline: 2px solid ${({ theme: { colors } }) => colors['gray']};
  }

  .a11y {
    ${a11y}
  }

  margin-left: 8px;

  :nth-of-type(2) {
    margin-left: 10px;
  }
`

export const SuggestedKeywords = styled.ul`
  margin-top: 9px;
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.12);
`

interface SuggestedKeywordProps {
  isFocused?: boolean
}

export const SuggestedKeyword = styled.li<SuggestedKeywordProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 68px;
  padding-left: 20px;
  line-height: 32px;
  width: 100%;

  :last-of-type {
    padding-bottom: 9px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow: hidden;
  }

  ${({ isFocused, theme: { colors } }) => {
    return isFocused
      ? css`
          font-weight: 700;
          background-color: ${colors['focused-bg']};
        `
      : ''
  }}
`

// ====================
export const OptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const DateRangePickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`

export const DateInputContainer = styled.div`
  display: flex;
  align-items: center;
`

export const DateInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 8px;
  outline: none;

  // 웹킷 기반 브라우저에서의 달력 아이콘 스타일 조정
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`

export const SearchButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`
