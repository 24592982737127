import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { videosDataType, videosType } from '@/types/videosType'

interface initialStateType {
  loading: boolean
  error: unknown
  allVideosDataCount: number
  videos: videosDataType[] | null
  pagingNumber: number
}

const initialState: initialStateType = {
  loading: false,
  error: null,
  allVideosDataCount: 0,
  videos: null,
  pagingNumber: 0,
}

const videos = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    getVideos: (state, action: PayloadAction<videosType>) => {
      const { meta, data } = action.payload

      state.allVideosDataCount = meta.count
      state.videos = data
      state.pagingNumber = 1

      return state
    },

    getNextVideosData: (
      state,
      action: PayloadAction<{
        response: videosType
        paging: number
      }>,
    ) => {
      const {
        response: { data },
        paging,
      } = action.payload

      state.videos = data
      state.pagingNumber = paging

      return state
    },

    reset: (state, action: PayloadAction<keyof initialStateType>) => {
      const id = action.payload

      if (id === 'videos') state[id] = null
    },
    error: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload

      return state
    },
  },
})

export const videosActions = { ...videos.actions }

export default videos
