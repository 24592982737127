import styled from '@emotion/styled'

interface VideoItemContainerType {
  $width: number | undefined
}

export const VideoItem = styled.li<VideoItemContainerType>`
  background: #ffffff;
  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  height: 270px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;

    .detail_box > p {
      text-decoration: underline;
    }
  }

  &:nth-of-type(n + 5) {
    margin-top: 24px;
  }

  .thumbnail_box {
    position: relative;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    width: 100%;
    height: calc(100% * 0.55);
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    span {
      margin-bottom: 7px;
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.5em;
      color: #ffffff;
    }

    .video_thumbnail {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;

      img {
        position: relative;
        width: 100%;
        transform: translate(-50%, -50%) scale(2.4);
      }
    }

    .video_thumbnail_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;
    }

    > svg {
      position: absolute;
      bottom: 0;
      left: 8px;
      z-index: 2;
    }

    span {
      position: absolute;
      bottom: 0;
      right: 17px;
      z-index: 2;
    }
  }

  .detail_box {
    flex-grow: 1;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    width: 100%;
    padding: 17px 18px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    color: #222222;

    p {
      height: 40px;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.03em;

      /* 말줄임 CSS */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .additional_info {
      display: flex;
      flex-wrap: wrap;

      font-size: 1.1rem;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.01em;

      color: #666;

      div {
        display: flex;
        align-items: center;
      }

      dt {
        display: inline-block;
        box-sizing: border-box;
      }

      dd {
        display: inline-block;
        margin-left: 5px;

        span {
          display: inline-block;
        }
      }

      time {
        display: inline-block;
      }
      div:nth-of-type(1) {
        width: 100%;
      }

      div:nth-of-type(n + 2) {
        width: 50%;
      }
    }
  }
`
