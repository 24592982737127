import { a11y } from '@/theme'
import styled from '@emotion/styled'
import DelIcon from '@/assets/images/word_chip_del.svg'
import DelHover from '@/assets/images/word_chip_del_hover.svg'

export const Wrapper = styled.div`
  width: 636px;
  margin: 150px auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
`

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 20px;
    color: ${({ theme: { colors } }) => colors['black']};
  }

  button {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 20px;
    color: ${({ theme: { colors } }) => colors['black']};
    opacity: 0.6;
    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 118px;

  :before {
    content: '';
    background-color: #cccccc;
    border-bottom: 1px solid white;
    width: 100%;
    height: 1px;
  }
`

export const Li = styled.li`
  height: 42px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
  display: inline-flex;
  align-items: flex-start;
  padding: 10px 20px;
  gap: 5px;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;

  :hover,
  :focus {
    border: 1px solid ${({ theme: { colors } }) => colors['blue']};
  }

  &.hidden {
    display: none;
  }

  &.invisible {
    opacity: 0;
  }
`

export const Word = styled.button`
  overflow: hidden;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: ${({ theme: { colors } }) => colors['black']};
  border-radius: 5px;

  :hover,
  :focus {
    color: ${({ theme: { colors } }) => colors['blue']};
    outline: 1px solid lightgray;
  }
`

export const Del = styled.button`
  background: transparent;
  width: 20px;
  height: 20px;
  background-image: url(${DelIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  border-radius: 5px;

  :hover,
  :focus {
    background-image: url(${DelHover});
  }

  .a11y {
    ${a11y}
  }
`
