import React from 'react'

import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

import { Route, Routes } from 'react-router-dom'

import { Home } from '@/pages/Home/Home'
import { Search } from '@/pages/search'
import { Page404 } from '@/pages/404/404'

import PrivateRoute from '@/layout/PrivateRoute'
import { ImageLens } from './pages/lens/ImageLens'

function App() {
  return (
    <>
      <Routes>
        {/* main */}
        <Route path='/' element={<Home />} />

        {/* Search images */}
        <Route
          path='/imageLens'
          element={
            <PrivateRoute>
              <ImageLens />
            </PrivateRoute>
          }
        />

        {/* search */}
        <Route
          path='/search/*'
          element={
            <PrivateRoute>
              <Search />
            </PrivateRoute>
          }
        />
        {/* else any */}
        <Route path='*' element={<Page404 />} />
      </Routes>
    </>
  )
}

export default App
