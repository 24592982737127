import styled from '@emotion/styled'

export const AudioItem = styled.li`
  background-color: white;
  display: flex;

  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;

    .detail_box {
      > p {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-weight: 700;
    color: #0f52ff;
  }
`

export const AudioInfoBox = styled.div`
  background-color: rgba(237, 237, 246, 1);
  flex: 0 0 23%;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  .icon_wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .play_info_box {
    background-color: #d6d6de;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 10px 4px 20px;
    border-bottom-left-radius: 8px;

    span {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.7rem;
      color: #222222;
    }
  }
`

export const AudioIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 33px;
  height: 33px;

  span {
    position: relative;
    background-color: #9d9db8;
    display: inline-block;
    width: 3px;
    border-radius: 1.5px;
  }

  span:nth-of-type(1) {
    height: 12px;
  }

  span:nth-of-type(2) {
    height: 33px;

    &:before,
    &::after {
      background-color: #9d9db8;
      content: '';
      position: absolute;
      width: 3px;
      border-radius: 1.5px;
    }

    &:before {
      top: calc(50% - 8px);
      left: -7.5px;
      height: 16px;
    }

    &:after {
      top: calc(50% - 11px);
      left: 7.5px;
      height: 22px;
    }
  }

  span:nth-of-type(3) {
    height: 11px;
  }
`
export const DetailBox = styled.div`
  padding: 30px 26px 20px 20px;
  color: #222222;

  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.3rem;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  .contents {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: -0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .additional_info {
    display: flex;
    align-items: center;

    margin-top: 15px;

    font-size: 1.1rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.01em;

    color: #666;

    div {
      display: flex;
      align-items: center;
    }

    div:nth-of-type(n + 2) {
      margin-left: 15px;
    }

    dt {
      display: inline-block;
      box-sizing: border-box;
    }

    dd {
      display: inline-block;
      margin-left: 5px;

      span {
        display: inline-block;
      }
    }

    time {
      display: inline-block;
    }
  }
`
