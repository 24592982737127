import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg'
import { ReactComponent as ArrowLeft } from '@/assets/svg/arrow-left.svg'
import styled from '@emotion/styled'

// export function CalendarHeader({
//   monthDate,
//   customHeaderCount,
//   decreaseMonth,
//   increaseMonth,
//   selectsRange,
// }: ReactDatePickerCustomHeaderProps & {
//   selectsRange?: boolean
// }): JSX.Element {
//   return (
//     <>
//       <Container>
//         <button
//           onClick={decreaseMonth}
//           style={{
//             visibility: customHeaderCount === 1 ? 'hidden' : undefined,
//           }}
//         >
//           <ArrowLeft />
//         </button>
//         <CalendarText>
//           {monthDate.toLocaleString('ko-kr', {
//             month: 'long',
//             year: 'numeric',
//           })}
//         </CalendarText>
//         <button
//           onClick={increaseMonth}
//           style={{
//             visibility: selectsRange ? (customHeaderCount === 0 ? 'hidden' : undefined) : undefined,
//           }}
//         >
//           <ArrowRight />
//         </button>
//       </Container>
//     </>
//   )
// }

export function CalendarHeader({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  selectsRange,
  changeYear, // 년도 변경 함수
  changeMonth, // 월 변경 함수
}: ReactDatePickerCustomHeaderProps & {
  selectsRange?: boolean
  changeYear?: (year: number) => void
  changeMonth?: (month: number) => void
}): JSX.Element {
  return (
    <>
      <Container>
        <button
          onClick={decreaseMonth}
          style={{
            visibility: customHeaderCount === 1 ? 'hidden' : undefined,
          }}
        >
          <ArrowLeft />
        </button>
        <select
          value={monthDate.getFullYear()}
          onChange={({ target }) => changeYear(parseInt(target.value, 10))}
          style={{ margin: '0 10px' }} // 스타일 추가
        >
          {[...Array(130)].fill(null).map((_, i) => {
            const year = new Date().getFullYear() - i
            return (
              <option key={year} value={year}>
                {year}
              </option>
            )
          })}
        </select>
        <select
          value={monthDate.getMonth()}
          onChange={({ target }) => changeMonth(parseInt(target.value, 10))}
          style={{ margin: '0 10px' }}
        >
          {[...Array(12)].map((_, i) => (
            <option key={i} value={i}>
              {new Date(0, i).toLocaleString('ko-KR', { month: 'long' })}
            </option>
          ))}
        </select>
        <button
          onClick={increaseMonth}
          style={{
            visibility: selectsRange ? (customHeaderCount === 0 ? 'hidden' : undefined) : undefined,
          }}
        >
          <ArrowRight />
        </button>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1rem;
  padding: 1.6rem;
  /* border: 1px solid transparent; */
  border-radius: 0.6rem 0.6rem 0 0;
  border-bottom: 1px solid #d8dbe3;
`

// const CalendarText = styled.span`
//   font-size: 1.4rem;
//   font-weight: 700;
//   line-height: 144%;
//   color: #5a6073;
// `
