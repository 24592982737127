import styled from '@emotion/styled'

export const ImgItem = styled.li`
  position: relative;
  border-radius: 8px;
  width: 180px;
  height: 140px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;

  :hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
