export default function convertTitle(title: string, type: string) {
  // 타이틀의 날짜 정보를 지우기 위한 임시 코드
  // 추후 서버단에서 영상 타이틀 정보 수정할 예정
  if (title.match('연합뉴스TV')) {
    return '연합뉴스TV'
  }

  if (title.match('뉴스공장')) {
    return '뉴스공장'
  }

  if (type === 'audio') {
    const newTitleArr = title.split('s)_')

    if (newTitleArr.length > 1) return newTitleArr[1]
    return newTitleArr[0]
  }

  if (type === 'video') {
    const newTitleArr = title.split('s)_')

    if (newTitleArr.length > 1) return newTitleArr[1]
    return newTitleArr[0]
  }

  return title
}
