import styled from '@emotion/styled'
import { css } from '@emotion/react'

import iconDocTtl from '@/assets/images/icon_doc_ttl.svg'
import iconImgTtl from '@/assets/images/icon_img_ttl.svg'
import iconMovTtl from '@/assets/images/icon_movie_ttl.svg'
import iconVocTtl from '@/assets/images/icon_voice_ttl.svg'

interface WrapperProps {
  title: '문서' | '이미지' | '동영상' | '음성' | '유사문서'
}

export const Wrapper = styled.div<WrapperProps>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  letter-spacing: -0.03em;
  line-height: 26px;
  margin-bottom: 24px;
  font-weight: 700;

  ::before {
    content: '';
    width: 24px;
    height: 24px;
    background-position: center center;
    background-repeat: no-repeat;
    margin-right: 3px;
    ${({ title }) => {
      switch (title) {
        case '문서':
        case '유사문서':
          return css`
            background-image: url(${iconDocTtl});
          `
        case '이미지':
          return css`
            background-image: url(${iconImgTtl});
          `
        case '동영상':
          return css`
            background-image: url(${iconMovTtl});
          `
        case '음성':
          return css`
            background-image: url(${iconVocTtl});
          `
      }
    }}
  }
`
