import { createAction } from 'typesafe-actions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import DocumentsService from '@/services/DocumentsService'
import { documentsActions } from '../documents'
import { RootState } from '..'
import { documentsDataType, documentsType } from '@/types/documentsType'
import { searchActions } from '../search'

const prefix = 'documents'

// 문서 목록 조회
const setDocumentsSagaAction = createAction(`${prefix}/DOCUMENTS_SAGA_ACTION`)<
  Record<string, string>
>()

function* setDocumentsSaga({ payload }: ReturnType<typeof setDocumentsSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus } = yield select(
    (state: RootState) => state.search,
  )
  const documents: documentsDataType[] | null = yield select(
    (state: RootState) => state.documents.documents,
  )

  try {
    if (documents && documents.length !== 0) yield put(documentsActions.reset('documents'))

    const { keyword, s_event_date, e_event_date } = payload

    const { data }: { data: documentsType } = yield call(DocumentsService.getDocuments, {
      keyword,
      s_event_date,
      e_event_date,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
    })

    yield put(documentsActions.getDocumentsData(data))
    yield put(searchActions.setSearchQuery(payload))
  } catch (err) {
    yield put(documentsActions.error(err))
  }
}

// 문서 페이지네이션
const getNextDocumentsSagaAction = createAction(
  `${prefix}/GET_NEXT_DOCUMENTS_SAGA_ACTION`,
)<number>()

function* getNextDocumentsSaga({ payload }: ReturnType<typeof getNextDocumentsSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus, searchQuery } = yield select(
    (state: RootState) => state.search,
  )

  try {
    const { data } = yield call(DocumentsService.getDocuments, {
      ...searchQuery,
      paging: payload,
      countPerPage: 10,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
    })

    yield put(documentsActions.getNextDocumentsData({ response: data, paging: payload }))
  } catch (e) {
    yield put(documentsActions.error(e))
  }
}

export const documentsSagaActions = {
  setDocumentsSagaAction,
  getNextDocumentsSagaAction,
}

export default function* documentsSaga() {
  yield takeLatest(setDocumentsSagaAction, setDocumentsSaga)
  yield takeLatest(getNextDocumentsSagaAction, getNextDocumentsSaga)
}
