import styled from '@emotion/styled'

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`
