import React from 'react'

import { A11yHidden, Logo, NavTab, HeaderSearchBar, Avatar } from '@/components'

import * as S from './Header.styled'
import { SearchAgent } from '@/components/SearchAgent/SearchAgent'

export function Header(): JSX.Element {
  return (
    <S.Header>
      <S.SearchBarWrapper>
        <S.Wrapper>
          <S.LogoH1>
            <a href='/'>
              <A11yHidden>T3Q.search+</A11yHidden>
              <Logo width={145} />
            </a>
          </S.LogoH1>
          <HeaderSearchBar />

          <S.Stack>
            <SearchAgent />
            <Avatar />
          </S.Stack>
        </S.Wrapper>
      </S.SearchBarWrapper>
      <NavTab id='gnbContent'>
        <NavTab.TabItem to='/'>전체</NavTab.TabItem>
        <NavTab.TabItem to='/document'>
          <NavTab.NavTabIcon to='/document' />
          문서
        </NavTab.TabItem>
        <NavTab.TabItem to='/image'>
          <NavTab.NavTabIcon to='/image' />
          이미지
        </NavTab.TabItem>
        <NavTab.TabItem to='/video'>
          <NavTab.NavTabIcon to='/video' />
          동영상
        </NavTab.TabItem>
        <NavTab.TabItem to='/audio'>
          <NavTab.NavTabIcon to='/audio' />
          음성
        </NavTab.TabItem>
      </NavTab>
    </S.Header>
  )
}
