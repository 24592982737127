import React, { forwardRef } from 'react'

import * as S from './VideoItem.styled'
import { ReactComponent as PlayIcon } from '@/assets/svg/playIcon.svg'
import { ReactComponent as VideoIcon } from '@/assets/svg/video.svg'
import { searchVideosType } from '@/types/searchType'
import { convertDuration, hasPopupSource } from '@/functions'
import convertTitle from '@/functions/convertTitle'

export interface VideoItemProps extends React.HTMLAttributes<HTMLElement> {
  dataItem: searchVideosType
  width?: number
}

export const VideoItem = forwardRef<HTMLLIElement, VideoItemProps>(
  ({ dataItem, width, ...restProps }: VideoItemProps, ref) => {
    const {
      title,
      date,
      thumbnail,
      file_play_time: filePlayTime,
      col_dt: colDt,
      data_src_l_cd: dataSrcLCd,
      data_src_m_cd: dataSrcMCd,
      data_src_s_cd: dataSrcSCd,
      score: score,
    } = dataItem

    return (
      <S.VideoItem ref={ref} $width={width} {...restProps}>
        <div className='thumbnail_box'>
          {thumbnail ? (
            <div className='video_thumbnail'>
              <img src={`${process.env.REACT_APP_BASE_URL}${thumbnail}`} alt='video 썸네일' />
            </div>
          ) : (
            <div className='video_thumbnail_icon'>
              <VideoIcon />
            </div>
          )}
          <PlayIcon width={44} height={44} />
          <span>{filePlayTime ? convertDuration(filePlayTime) : '05:00'}</span>
        </div>
        <div className='detail_box'>
          <p role='doc-subtitle'>{convertTitle(title ?? '', 'video')}</p>
          <dl className='additional_info'>
            <div>
              <dt>출처:</dt>
              <dd>
                <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
              </dd>
            </div>
            <div>
              <dt>게시일: </dt>
              <dd>
                <span>
                  <time dateTime={date}>{date.slice(0, -9).replaceAll('-', '. ')}&#46;</time>
                </span>
              </dd>
            </div>
            <div>
              <dt>수집일:</dt>
              <dd>
                <span>
                  <time dateTime={colDt}>{colDt.slice(0, -9).replaceAll('-', '. ')}&#46;</time>
                </span>
              </dd>
            </div>
            {score && (
              <div>
                <dt>유사도:</dt>
                <dd>
                  <span>{score}</span>
                </dd>
              </div>
            )}
          </dl>
        </div>
      </S.VideoItem>
    )
  },
)

VideoItem.displayName = 'VideoItem'
