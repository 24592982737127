import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { RemoveScroll } from 'react-remove-scroll'

import styled from '@emotion/styled'

import { A11yHidden } from '@/components'
import { ReactComponent as Close } from '@/assets/svg/close-popup.svg'
import { useDispatch } from 'react-redux'

import { searchActions } from '@/store/search'
import { useSelector } from '@/store'

const Container = styled.div<{ $isLoading: boolean; $role: string }>`
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  overflow-x: hidden;
  border: 2px solid black;
  z-index: 1000000000;

  :focus {
    border-color: blue;
  }

  .modal_contents {
    position: relative;
    width: ${({ $role }) => ($role === 'version_potal' ? '400px' : '960px')};
    padding-top: 37px;
    margin: ${({ $isLoading }) => ($isLoading ? '20px auto 0' : '20px auto auto')};
    z-index: 10000;
    border: 1px solid transparent;
  }
`

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
`

const useMomodal = (targetRef: HTMLElement | null) => {
  // DOM Node 참조
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [momodalOpened, setMomodalOpened] = useState(false)
  const { loading, detailMomodalData } = useSelector((state) => state.search)

  const dispatch = useDispatch()

  const openMomodal = () => {
    setMomodalOpened(true)
  }

  const closeMomodal = () => {
    setMomodalOpened(false)

    if (targetRef) window.setTimeout(() => targetRef.focus())

    dispatch(searchActions.reset('detailMomodalData'))
  }

  const handleClickCloseMomodal = (e: React.MouseEvent) => {
    const target = e.target as HTMLDivElement

    if (target.id !== 'momodal_container') return
    setMomodalOpened(false)

    if (targetRef) window.setTimeout(() => targetRef.focus())
    dispatch(searchActions.reset('detailMomodalData'))
  }

  useLayoutEffect(() => {
    if (detailMomodalData) window.setTimeout(() => contentRef.current?.focus())
  }, [detailMomodalData])

  interface PortalProps {
    id: string
    children: React.ReactNode
  }

  const MomodalPortal: React.FC<PortalProps> = ({ id, children }) => {
    const ref = useRef<Element | null>()
    const closeButtonRef = useRef<HTMLButtonElement | null>(null)

    const [mounted, setMounted] = useState<boolean>(false)

    const handleFocusMomodal = useCallback((e: React.KeyboardEvent) => {
      if (!e.shiftKey && e.key === 'Escape') {
        closeMomodal()
      }

      if (!e.shiftKey && e.key === 'Tab') {
        e.preventDefault()

        if (!contentRef.current) return

        contentRef.current.focus()
      }
    }, [])

    const handleFocus = useCallback((e: React.KeyboardEvent) => {
      if (!e.shiftKey && e.key === 'Escape') {
        return closeMomodal()
      }

      const target = e.target as HTMLDivElement

      if (e.shiftKey && e.key === 'Tab' && target.className === 'modal_contents') {
        e.preventDefault()

        if (!closeButtonRef.current) return

        closeButtonRef.current.focus()
      }
    }, [])

    useLayoutEffect(() => {
      setMounted(true)

      if (document) {
        const dom = document.querySelector('#root-modal')
        ref.current = dom
      }
    }, [])

    if (ref.current && mounted && momodalOpened) {
      return createPortal(
        <RemoveScroll>
          <Container
            role='dialog'
            id='momodal_container'
            onClick={handleClickCloseMomodal}
            $isLoading={loading}
            $role={id}
          >
            <div className='modal_contents' ref={contentRef} tabIndex={0} onKeyDown={handleFocus}>
              {children}
              <CloseButton
                type='button'
                className='link_close'
                ref={closeButtonRef}
                onKeyDown={handleFocusMomodal}
                onClick={closeMomodal}
              >
                <Close />
                <A11yHidden>팝업 닫기</A11yHidden>
              </CloseButton>
            </div>
          </Container>
        </RemoveScroll>,
        ref.current,
      )
    }

    return null
  }

  return { momodalOpened, openMomodal, closeMomodal, MomodalPortal }
}

export default useMomodal
