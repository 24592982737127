import styled from '@emotion/styled'
import { a11y } from '@/theme'
import left from '@/assets/images/left.svg'
import right from '@/assets/images/right.svg'

export const Wrapper = styled.div`
  ul {
    display: flex;
    justify-content: center;
    color: #a7a7a7;
    gap: 10px;
  }

  li {
    button {
      min-width: 37px;
      padding: 0 5px;
      box-sizing: border-box;
      height: 37px;
      border-radius: 4px;
      border: 1px solid #dfe3e8;
      background-color: white;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 140%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      :focus,
      :hover {
        color: ${({ theme: { colors } }) => colors['black']};
        border: 2px solid ${({ theme: { colors } }) => colors['gray']};
      }

      .a11y {
        ${a11y}
      }
    }
  }

  li.rc-pagination-next {
    button {
      margin-right: 0;
    }
  }

  li.rc-pagination-item-active {
    button {
      color: ${({ theme: { colors } }) => colors['blue']};
      border: 1px solid ${({ theme: { colors } }) => colors['blue']};
    }
  }

  li.rc-pagination-disabled {
    button {
      cursor: not-allowed;
      color: lightgray;

      :focus,
      :hover {
        color: lightgray;
        border: 1px solid #dfe3e8;
      }
    }
  }

  li.rc-pagination-prev {
    button {
      background-position: center center;
      background-image: url(${left});
      background-size: 28px 28px;
      background-repeat: no-repeat;
    }
  }

  /* li.rc-pagination-prev.rc-pagination-disabled {
    button {
      background-position: center center;
      background-image: url(${left});
      background-size: 28px 28px;
      background-repeat: no-repeat;
    }
  } */

  li.rc-pagination-next {
    button {
      background-position: center center;
      background-image: url(${right});
      background-size: 28px 28px;
      background-repeat: no-repeat;
    }
  }

  /* li.rc-pagination-next.rc-pagination-disabled {
    button {
      background-position: center center;
      background-image: url(${right});
      background-size: 28px 28px;
      background-repeat: no-repeat;
    }
  } */
`
