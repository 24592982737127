import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import PdfViewer from './PdfViewer'
import parse from 'html-react-parser'
import { checkFileExtention, keywordHighlighting } from '@/functions'

const Container = styled.div`
  font-size: 1.4rem;
  line-height: 160%;
  font-weight: 400;
  letter-spacing: -0.01em;

  pre {
    height: 100%;
    padding: 5px 12px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    overflow: auto;
    white-space: pre-wrap;
  }
`

interface FileViewerProps {
  file: string
  contents?: string
  keyword: string
}

export function FileViewer({ file, contents, keyword }: FileViewerProps) {
  const fileExtensionNameArr = ['pdf', 'hwp', 'doc', 'docx', 'jpg', 'wav', 'png']

  const exportExtenstionViewer = useCallback(
    (name: string | null) => {
      switch (name) {
        case 'pdf':
          return <PdfViewer file={file} />
        case 'jpg':
          return <img src={`${process.env.REACT_APP_BASE_URL}${file}`} alt="document's image" />
        case 'png':
          return <img src={`${process.env.REACT_APP_BASE_URL}${file}`} alt="document's image" />
        default:
          return <pre>{contents && parse(keywordHighlighting(contents, keyword))}</pre>
      }
    },
    [contents, file, keyword],
  )

  return (
    <Container>{exportExtenstionViewer(checkFileExtention(file, fileExtensionNameArr))}</Container>
  )
}
