import React from 'react'

import styled from '@emotion/styled'

export const ViewMoreButtonStyle = styled.button`
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #a7a7a7;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #ededf6;

  :hover {
    color: #222;
  }
`
type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

export function ViewMoreButton({ ...props }: Props) {
  return (
    <ViewMoreButtonStyle type='button' className='bth_viewMoer' {...props}>
      결과 더 보기
    </ViewMoreButtonStyle>
  )
}
