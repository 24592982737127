import React, { memo, useRef, useState, useEffect } from 'react'

import { usePdf } from '@mikecousins/react-pdf'

import styled from '@emotion/styled'
import left from '@/assets/images/left.svg'
import right from '@/assets/images/right.svg'
import { A11yHidden } from '../common/A11yHidden'
import axios from '@/services'

const Container = styled.div`
  width: 100%;

  .pdf_document {
    position: relative;
    overflow: hidden;
  }

  canvas {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
    width: 100% !important;
  }
`

const ButtonBox = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;

  display: flex;
  align-items: center;

  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  overflow: hidden;
  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);

  span {
    font-size: 1.2rem;
  }

  button {
    width: 37px;
    height: 37px;
  }

  button:nth-of-type(1) {
    background-position: center center;
    background-image: url(${left});
    background-size: 28px 28px;
    background-repeat: no-repeat;
  }

  button:nth-of-type(2) {
    background-position: center center;
    background-image: url(${right});
    background-size: 28px 28px;
    background-repeat: no-repeat;
  }
`

interface PdfViewerProps {
  file: string
}
const PdfViewer = ({ file }: PdfViewerProps) => {
  const [pdfUrl, setPdfUrl] = useState('')
  const file_path = file.replace('/file/', '')

  // TODO : post 로 받아서 처리
  const fetchPdfFile = async () => {
    try {
      const response = await axios.post(
        'file',
        {
          file_path: `${file_path}`,
        },
        {
          responseType: 'blob',
        },
      )

      if (response.status !== 200) {
        throw new Error('서버 에러')
      }

      const pdfFileUrl = URL.createObjectURL(new Blob([response.data]))

      console.log(pdfFileUrl)
      setPdfUrl(pdfFileUrl)
    } catch (error) {
      console.error('PDF 파일을 불러오는 중 에러 발생:', error)
    }
  }

  useEffect(() => {
    fetchPdfFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className='pdf_viewer'>
      {pdfUrl && <PdfComponent fileUrl={pdfUrl} />}
      {/* <Document file='/static/sample_v.pdf' onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
        <Page pageNumber={pageNumber} />
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      <ButtonBox className='controller_box'>
        <button
          onClick={() => {
            if (pageNumber > 1) {
              setPageNumber((prevPageNumber) => prevPageNumber - 1)
            } else {
              return null
            }
          }}
        >
          <A11yHidden>이전</A11yHidden>
        </button>
        <span>
          {pageNumber} of {numPages}
        </span>
        <button
          onClick={() => {
            if (!numPages) return

            if (pageNumber < numPages) {
              setPageNumber((prevPageNumber) => prevPageNumber + 1)
            } else {
              return null
            }
          }}
        >
          <A11yHidden>다음</A11yHidden>
        </button>
      </ButtonBox> */}
    </Container>
  )
}

export default memo(PdfViewer)

function PdfComponent({ fileUrl }: { fileUrl: string }) {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const { pdfDocument } = usePdf({
    file: fileUrl,
    page: pageNumber,
    canvasRef,
    scale: 1,
    onDocumentLoadSuccess: ({ _pdfInfo: { numPages } }) => setNumPages(numPages),
  })

  return (
    <>
      {!pdfDocument && <span>Loading...</span>}
      <div className='pdf_document'>
        <canvas ref={canvasRef} />
        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <ButtonBox className='controller_box'>
            <button
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1)
                } else {
                  return null
                }
              }}
            >
              <A11yHidden>이전</A11yHidden>
            </button>
            <span>
              {pageNumber} of {numPages}
            </span>
            <button
              onClick={() => {
                if (!numPages) return

                if (pageNumber < numPages) {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1)
                } else {
                  return null
                }
              }}
            >
              <A11yHidden>다음</A11yHidden>
            </button>
          </ButtonBox>
        )}
      </div>
    </>
  )
}
