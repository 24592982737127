import React, { useEffect, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useSelector } from '@/store'
import { audiosSagaActions } from '@/store/saga/audiosSaga'
import { searchSagaActions } from '@/store/saga/searchSaga'

import {
  SearchResultNumberBox,
  Pagination,
  SectionTitle,
  AudioItem,
  Loading,
  SearchNoResult,
  Voice,
  PopupLoading,
} from '@/components'

import useModal from '@/hooks/useModal'

import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

const Container = styled(CommonLayout)`
  min-height: calc(100vh - 317px);

  .search_result_voice_wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    display: flex;
    flex-flow: column nowrap;
    /* width: 792px; */
    height: 100%;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  .voice_list {
    flex-grow: 1;

    width: 100%;
    margin-bottom: 40px;

    li + li {
      margin-top: 10px;
    }
  }
`

export function SearchResultVoice() {
  /** router */
  const [searchParams] = useSearchParams()

  /** redux */
  const dispatch = useDispatch()
  const { allVoicesDataCount, audios } = useSelector((state) => state.audios)
  const { detailData } = useSelector((state) => state.search)

  /** useState */
  const [currentPage, setCurrentPage] = useState(1)
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** hooks */
  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement
      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'voi' }))
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'voi' }))
    },
    [dispatch, openModal],
  )

  /** 페이지네이션 */
  const handlePagination = useCallback(
    (page: number) => {
      setCurrentPage(page)

      dispatch(audiosSagaActions.getNextAudiosSagaAction(page))
    },
    [dispatch],
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /** 검색 결과 데이터 요청 */
  useEffect(() => {
    const keyword = searchParams.get('keyword')
    const s_event_date = searchParams.get('s_event_date')
    const e_event_date = searchParams.get('e_event_date')

    // if (keyword === null) return

    const params: Record<string, string> = {
      keyword: keyword ?? '',
      s_event_date: s_event_date ?? '',
      e_event_date: e_event_date ?? '',
    }

    dispatch(audiosSagaActions.getAudiosSagaAction(params))
  }, [dispatch, searchParams])

  // 데이터가 없는 경우
  if (!audios)
    return (
      <Container>
        <Loading />
      </Container>
    )

  if (allVoicesDataCount === 0)
    return (
      <Container>
        <div className='search_result_voice_wrapper'>
          <SearchResultNumberBox count={0} />
          <div className='search_result_inner'>
            <div style={{ width: '100%' }}>
              <SearchNoResult sectionClassName='img' />
            </div>
          </div>
        </div>
      </Container>
    )

  return (
    <Container>
      <div className='search_result_voice_wrapper'>
        <SearchResultNumberBox count={allVoicesDataCount} />
        <div className='search_result_inner'>
          <SectionTitle title='음성' />
          <ul className='voice_list'>
            {audios.map((item) => (
              <AudioItem
                key={item.id}
                id={item.id}
                data={item}
                tabIndex={0}
                onClick={handleShowPopup}
                onKeyDown={handleKeyDownShowPopup}
              />
            ))}
          </ul>
          {10 < allVoicesDataCount && (
            <Pagination
              currentPage={currentPage}
              setCurrent={handlePagination}
              perPage={10}
              totalRecordCount={allVoicesDataCount}
            />
          )}
        </div>
      </div>
      <ModalPortal id={'modal_potal'}>{detailData ? <Voice /> : <PopupLoading />}</ModalPortal>
    </Container>
  )
}
