import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { GlobalStyle, commonTokens } from '@/theme'

interface AppThemeContainerProps {
  children: JSX.Element
}

export function AppThemeContainer({ children }: AppThemeContainerProps): JSX.Element {
  return (
    <>
      <ThemeProvider theme={commonTokens}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </>
  )
}
