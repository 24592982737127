// db.js
import Dexie, { Table } from 'dexie'

export interface Keyword {
  word: string
  created: number
}

export class RecentlySearchedKeywordDB extends Dexie {
  keywords!: Table<Keyword>

  constructor() {
    super('RecentlySearchedKeyword')
    this.version(1).stores({
      keywords: 'word',
    })
  }

  async add(searchText: string) {
    try {
      if (await this.get(searchText)) {
        this.keywords.update(searchText, { created: new Date().getTime() })
        return
      }
      if ((await this.keywords.count()) >= 20) {
        await this.keywords.toCollection().sortBy('created', ([first]) => {
          if (!first) return
          this.del(first.word)
        })
      }

      await this.keywords.add({
        word: searchText,
        created: new Date().getTime(),
      })
      // console.log(`id: ${id}가 indexedDB에 입력되었습니다.`)
    } catch (error) {
      ;((error) => {
        if (error instanceof Error) {
          if (error.name != 'ConstraintError') {
            // console.log('IndexedDB 정보 입력 중 오류가 발생했습니다.', error)
          }
        }
      })(error)
    }
  }

  async get(searchText: string) {
    return await this.keywords.get(searchText)
  }

  async getRecent10(fn: (arr: Keyword[]) => void) {
    return await this.keywords.toCollection().reverse().limit(10).sortBy('created', fn)
  }

  async getAll(fn: (arr: Keyword[]) => void) {
    return await this.keywords.toCollection().reverse().sortBy('created', fn)
  }

  async del(searchText: string) {
    await this.keywords.delete(searchText)
  }

  async delAll() {
    await this.keywords.clear()
  }
}

export const db = new RecentlySearchedKeywordDB()
