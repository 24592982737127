import styled from '@emotion/styled'
import { css } from '@emotion/react'
import search from '@/assets/images/search.svg'
import mic from '@/assets/images/mic.svg'
import { a11y } from '@/theme'

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`

export const SearchBarSecondChildWrapper = styled.div`
  margin-top: 21px;
  padding-left: 33px;
  width: 100%;
  height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 500;

  .group_keywords {
    span {
      margin-left: 20px;
    }
  }

  .bar {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 100;
  }

  .title {
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors['blue']};
  }

  .word {
    ::before {
      content: '#';
    }
  }

  button {
    display: inline-block;
    max-width: 145px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const SearchBar = styled.div`
  width: min(50vw, 636px);
  height: 70px;
  overflow: visible;
  box-sizing: border-box;
  border: 4px solid #0f52ff;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  background-color: #0f52ff;
  position: relative;
  z-index: 1;
`
export const SearchBarInner = styled.div`
  background-color: white;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 30px 15px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.12);
  border-radius: 31px;
  min-height: 62px;
  box-sizing: border-box;
  padding-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
`

interface PlaceholderProps {
  isHidden: boolean
}

export const Placeholder = styled.div<PlaceholderProps>`
  position: absolute;
  top: 17px;
  left: 70px;
  background-color: white;
  border: 1px solid white;
  box-sizing: border-box;
  line-height: 32px;
  width: calc(100% - 68px - 26px - 52px);
  font-size: 2rem;
  font-weight: 500;
  color: #666666;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  z-index: 2;
  cursor: text;

  strong {
    font-weight: 700px;
    color: #0f52ff;
  }
`

export const SearchBarInputGroup = styled.div`
  padding-left: 68px;
  padding-right: 68px;

  display: flex;
  width: 100%;
  align-items: center;

  input {
    height: 30px;
    width: 100%;
    font-size: 2rem;
    border: 0;

    :focus {
      outline: none;
    }
  }

  button {
    background-image: url(${mic});
    background-position: center center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 5px;

    :focus {
      outline: 2px solid ${({ theme: { colors } }) => colors['gray']};
    }
    .a11y {
      ${a11y}
    }
  }
`

export const SuggestedKeywords = styled.ul`
  margin-top: 9px;
  font-size: 1.6rem;
  font-weight: 300;
  width: 100%;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 31px;
  border-bottom-right-radius: 31px;
`

interface SuggestedKeywordProps {
  isFocused?: boolean
}

export const SuggestedKeyword = styled.li<SuggestedKeywordProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 68px;
  padding-left: 68px;
  line-height: 45px;
  width: 100%;

  :last-of-type {
    border-bottom-left-radius: 31px;
    border-bottom-right-radius: 31px;
    padding-bottom: 9px;
    overflow: hidden;
  }

  ${({ isFocused, theme: { colors } }) => {
    return isFocused
      ? css`
          font-weight: 700;
          background-color: ${colors['focused-bg']};
        `
      : ''
  }}
`

/* --------------------------------- 이미지 검색 --------------------------------- */

export const ImgSearchBth = styled.button`
  position: absolute;
  right: 30px;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);

  width: 32px;
  height: 32px;
`

export const ImgSearchLayer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  width: min(50vw, 636px);
  height: 270px;

  border-radius: 12px;
  box-shadow: 0px 35px 50px 0px #0000001f;
  background-color: #fff;

  .submit_btn {
    align-self: center;

    display: inline-block;
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    background: #0f52ff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
  }
`

export const DropzoneWrapper = styled.div<{ $isDragActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 38px 0;
  height: 120px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background-color: #f8f8fc;

  > p {
    margin-top: 5px;
    font-size: 14px;
    color: #0f52ff;
  }

  > button {
    margin-bottom: 3rem;
  }

  label {
    ${a11y}
  }
`
