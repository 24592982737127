import axios from '.'

export default class ImagesService {
  /** 이미지 목록 조회 */
  static async getImages(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
  }) {
    return axios.get('/images/', { params })
  }

  /** 이미지 상세 조회 */
  static async getImagesDetail(imageId: string, searchKeyword: string) {
    return axios.get(`/images/${imageId}?keyword=${searchKeyword}`)
  }
}
