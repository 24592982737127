import React, { useEffect, useState } from 'react'
import * as S from './NavTab.styled'
import { NavTabProvider } from './useNavTabContext'
import { TabItem } from './TabItem'
import { NavTabIcon } from './NavTabIcon'
import { useLocation } from 'react-router-dom'

interface NavTabProps extends React.HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[]
  pathBase?: string
}

export function NavTab({ children, pathBase = '/search', ...restProps }: NavTabProps): JSX.Element {
  const execResult = /\/search(\/[a-zA-Z0-9_-]{0,})[/,?]/.exec(window.location.href)
  const tab = execResult ? execResult[1] : '/'
  const [currentTab, setCurrentTab] = useState(tab)
  const location = useLocation()

  useEffect(() => {
    setCurrentTab(tab)
  }, [location, tab])

  return (
    <NavTabProvider value={{ currentTab, pathBase }}>
      <S.OuterWrapper {...restProps}>
        <S.InnerWrapper>{children}</S.InnerWrapper>
      </S.OuterWrapper>
    </NavTabProvider>
  )
}

NavTab.TabItem = TabItem
NavTab.NavTabIcon = NavTabIcon
