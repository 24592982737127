import React, { ReactNode } from 'react'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'

import { A11yDirection } from '@/components'

const defaultProps = {}

export interface LayoutProps {
  children: ReactNode
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <A11yDirection />
      <Header></Header>
      <main id='mainContent'>{children}</main>
      <Footer></Footer>
    </>
  )
}

Layout.defaultProps = defaultProps
