import React, { useCallback } from 'react'

import styled from '@emotion/styled'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { searchActions } from '@/store/search'
import { useSelector } from '@/store'

const Button = styled.button`
  padding: 7px 16px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 4px;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.03em;
  color: #666666;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
  }

  &:hover {
    border-color: #0f52ff;
    color: #0f52ff;
  }
`

interface Props {
  word: string
}

const RelatedWordUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-left: -5px;
  margin-right: -5px;

  li {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 5px;
    margin-right: 5px;
  }
`

export function RelatedWord({ word }: Props) {
  const [, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const { searchQuery } = useSelector((state) => state.search)

  const clickHandler = useCallback(() => {
    const { s_event_date, e_event_date } = searchQuery
    const params = {
      keyword: `${word}`,
    } as Record<string, string>

    dispatch(searchActions.setSearchQuery(params))

    if (s_event_date) params['s_event_date'] = s_event_date
    if (e_event_date) params['e_event_date'] = e_event_date

    setSearchParams(params)
  }, [dispatch, searchQuery, setSearchParams, word])

  return (
    <Button onClick={clickHandler} title={word}>
      <span>{word}</span>
    </Button>
  )
}

RelatedWord.RelatedWordUl = RelatedWordUl
