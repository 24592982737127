function regExp(str: string) {
  // eslint-disable-next-line no-useless-escape
  const reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi

  if (reg.test(str)) {
    return str.replace(reg, ' ').trim()
  } else {
    return str
  }
}

export function keywordHighlighting(contents: string, keyword: string) {
  const keywordArr = regExp(keyword)
    .split(' ')
    .filter((word) => word !== '')

  let newContents = contents

  if (!keywordArr) return contents

  keywordArr.forEach((word: string) => {
    const regexText = `(?![^<]*?</strong>)${word}`

    const regex = new RegExp(regexText, 'gi')

    newContents = newContents
      ? newContents.replaceAll(regex, `<strong>${word}</strong>`)
      : String(newContents)
  })

  return newContents
}
