import React from 'react'
import { useSearchParams } from 'react-router-dom'

import * as S from './SearchNoResult.styled'

interface SearchNoResultProps {
  sectionClassName: 'doc' | 'img' | 'movie' | 'voice' | 'all'
}

export function SearchNoResult({ sectionClassName }: SearchNoResultProps): JSX.Element {
  const [searchParams] = useSearchParams()

  return (
    <S.Container className={`${sectionClassName}`}>
      <h2>
        <strong>{searchParams.get('keyword')}</strong>에 대한 검색결과가 없습니다.
      </h2>
      <ul>
        <li>단어의 철자가 정확한지 확인해 보세요.</li>
        <li>한글을 영어로 혹은 영어를 한글로 입력했는지 확인해 보세요.</li>
        <li>검색어의 단어 수를 줄이거나, 보다 일반적인 검색어로 다시 검색해 보세요.</li>
        <li>두 단어 이상의 검색어인 경우, 띄어쓰기를 확인해 보세요.</li>
        <li>검색 옵션을 변경해서 다시 검색해 보세요.</li>
      </ul>
    </S.Container>
  )
}
