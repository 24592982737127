export function hasPopupSource(dataSrcL: string, dataSrcM: string, dataSrcS: string) {
  let result = dataSrcL

  if (dataSrcM !== '') {
    result = result + ` > ${dataSrcM}`
  }

  if (dataSrcS !== '') {
    result = result + ` > ${dataSrcS}`
  }

  return result
}
