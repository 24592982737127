import axios from '.'

export default class DocumentsService {
  /** 문서 목록 조회 */
  static async getDocuments(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
  }) {
    return await axios.get('/documents/', {
      params,
    })
  }

  /** 문서 상세 조회 */
  static async getDocumentsDetail(documentId: string, searchKeyword: string) {
    return await axios.get(`/documents/${documentId}?keyword=${searchKeyword}`)
  }
}
