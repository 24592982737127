import { css } from '@emotion/react'
import styled from '@emotion/styled'
import check from '@/assets/images/check.svg'

export const Wrapper = styled.div`
  display: flex;
  height: 22px;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.03em;
  white-space: nowrap;

  &:focus-within {
    label {
      box-shadow: 0 0 0 0.1rem #0f52ff;
    }
  }

  input {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    background-color: transparent;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }
`

interface LabelProps {
  isGray: boolean
  checked: boolean
}

export const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ isGray, theme: { colors } }) => {
    return isGray ? colors['black'] : colors['blue']
  }};

  :focus {
    outline: 2px solid ${({ theme: { colors } }) => colors['gray']};
  }

  :hover {
    cursor: pointer;
  }

  ::before {
    content: '';
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1.5px
      ${({ isGray, theme: { colors } }) => {
        return isGray ? colors['black'] : colors['blue']
      }};
    ${({ checked, isGray, theme: { colors } }) => {
      const background = css`
        background-color: ${colors[`${isGray ? 'black' : 'blue'}`]};
        background-image: url(${check});
        background-position: center center;
        background-repeat: no-repeat;
      `
      return checked ? background : ''
    }}
  }

  span {
    margin-left: 12px;
    letter-spacing: -0.03em;
  }
`

interface TootipProps {
  $isGray: boolean
}

export const Tootip = styled.div<TootipProps>`
  position: relative;

  :hover svg {
    cursor: help;
  }

  svg {
    margin-left: 5px;
    padding: 10px 0;
    width: 22px;
    height: 42px;

    path {
      fill: ${({ $isGray }) => $isGray && '#222222'};
    }
  }

  :hover div {
    display: block;
  }

  div {
    display: none;
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1.25em 1.583em 0.999em;
    border: solid 1px #e1e1e1;
    box-shadow: 0 35px 50px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    width: 300px;
    font-size: 1.2rem;
    background-color: #fff;

    strong {
      display: block;
      margin-bottom: 5px;
      font-size: inherit;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: -0.01em;
      color: #0f52ff;
    }

    p {
      font-size: inherit;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: -0.01em;
    }
  }
`
