import React, { createContext, useContext } from 'react'

export interface NavTabContextProps {
  currentTab: string
  pathBase: string
}

export interface NavTabProviderProps {
  children: JSX.Element
  value: NavTabContextProps
}

const NavTabContext = createContext<NavTabContextProps | undefined>(undefined)

function NavTabProvider({ children, value }: NavTabProviderProps) {
  return <NavTabContext.Provider value={value}>{children}</NavTabContext.Provider>
}

function useNavTabContext() {
  const context = useContext(NavTabContext)
  if (context === undefined)
    throw new Error('useNavTabContext는 반드시 NavTabProvider와 함께 사용해야 합니다.')

  return context
}

export { NavTabProvider, useNavTabContext }
