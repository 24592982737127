import { useReactKeycloackId } from 'react-keycloak-id'

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const keycloak = useReactKeycloackId()

  const isLoggedIn = keycloak.authenticated
  const isKeycloakLogin = process.env.REACT_APP_KEYCLOAK_LOGIN_YN || 'N'

  if (isKeycloakLogin === 'Y' && !isLoggedIn) {
    keycloak.login()
    return null
  }

  return <>{children}</>
}

export default PrivateRoute
