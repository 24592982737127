import styled from '@emotion/styled'

interface DocItemProps {
  $hasImg?: boolean
}

export const DocItem = styled.li<DocItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: ${({ $hasImg }) => ($hasImg ? '0 20px 0 30px' : '0 30px')};
  width: 100%;
  height: 135px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;

  :nth-of-type(n + 2) {
    margin-top: 10px;
  }

  :hover {
    cursor: pointer;
    .doc_info p:nth-of-type(1) {
      text-decoration: underline;
    }
  }

  .doc_info {
    max-width: ${({ $hasImg }) => ($hasImg ? 'calc(100% - 142px);' : '100%')};
    width: 100%;

    p:nth-of-type(1) {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: -0.03em;
    }

    p:nth-of-type(2) {
      margin-top: 10px;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.01em;

      /* 말줄임 CSS */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    strong {
      font-weight: 700;
      color: #0f52ff;
    }
  }

  .additional_info {
    display: flex;
    align-items: center;

    margin-top: 15px;

    font-size: 1.1rem;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.01em;

    color: #666;

    div {
      display: flex;
      align-items: center;
    }

    div:nth-of-type(n + 2) {
      margin-left: 15px;
    }

    dt {
      display: inline-block;
      box-sizing: border-box;
    }

    dd {
      display: inline-block;
      margin-left: 5px;

      span {
        display: inline-block;
      }
    }

    time {
      display: inline-block;
    }
  }

  .doc_thumb {
    margin-left: 42px;
    border-radius: 4px;
    overflow: hidden;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`
