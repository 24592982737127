import styled from '@emotion/styled'
import React from 'react'

const Container = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 1000;
  height: 1px;

  a {
    position: absolute;
    top: -999px;
    padding: 5px 10px;
    font-weight: 700;
    color: #fff;
    background: #333;
    text-decoration: none;
    white-space: nowrap;
  }

  a:focus {
    top: 0;
  }
`

export function A11yDirection() {
  return (
    <Container>
      <a href='#mainContent'>본문 바로가기</a>
      <a href='#gnbContent'>메뉴 바로가기</a>
    </Container>
  )
}
