import { imagesDataType, imagesType } from '@/types/imagesType'
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'

interface initialStateType {
  loading: boolean
  error: unknown
  allImagesDataCount: number
  images: imagesDataType[] | null
  pagingNumber: number
  isEnd: boolean
}

const initialState: initialStateType = {
  loading: false,
  error: null,
  allImagesDataCount: 0,
  images: null,
  pagingNumber: 0,
  isEnd: false,
}

const images = createSlice({
  name: 'images',
  initialState,
  reducers: {
    getImages: (state, action: PayloadAction<imagesType>) => {
      const { meta, data } = action.payload

      state.allImagesDataCount = meta.count
      state.images = data
      state.pagingNumber = 1

      return state
    },
    getNextImages: (state, action: PayloadAction<{ data: imagesType; pagingNumber: number }>) => {
      const {
        data: { meta, data },
        pagingNumber,
      } = action.payload

      const images = current(state.images)

      if (images === null) return state

      state.pagingNumber = pagingNumber
      state.images = [...images, ...data]

      if (meta.count === 0) state.isEnd = true

      return state
    },
    reset: (state, action: PayloadAction<keyof initialStateType>) => {
      if (action.payload === 'images') state = initialState

      return state
    },
    error: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload

      return state
    },
  },
})

export const imagesActions = { ...images.actions }

export default images
