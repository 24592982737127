import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

interface useObserverProps {
  callback(entries: ResizeObserverEntry[]): void

  element: string
}

const useObserver = ({ callback, element }: useObserverProps) => {
  useEffect(() => {
    const ro = new ResizeObserver(callback)
    const el = document.getElementById(element)

    if (el) {
      ro.observe(el)
    }

    return () => {
      ro.disconnect()
    }
  }, [callback, element])
}

export default useObserver
