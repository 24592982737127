import styled from '@emotion/styled'

export const Header = styled.header`
  background-color: white;
  position: relative;
  width: 100%;
  box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.03);
  z-index: 100;
`
/** 공통 Wrapper */
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
`

export const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  border-bottom: 0.5px solid #e1e1e1;
`

export const TabNavWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LogoH1 = styled.h1`
  a {
    display: inline-block;
  }
`

export const Stack = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
