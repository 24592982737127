import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Layout } from '@/layout/Layout'
import { SearchResultAll } from './SearchResultAll'
import { SearchResultDoc } from './SearchResultDoc'
import { SearchResultImg } from './SearchResultImg'
import { SearchResultMovie } from './SearchResultMovie'
import { SearchResultVoice } from './SearchResultVoice'

export function Search() {
  return (
    <Layout>
      <Routes>
        <Route path='' element={<SearchResultAll />} />
        <Route path='document' element={<SearchResultDoc />} />
        <Route path='image' element={<SearchResultImg />} />
        <Route path='video' element={<SearchResultMovie />} />
        <Route path='audio' element={<SearchResultVoice />} />
      </Routes>
    </Layout>
  )
}
