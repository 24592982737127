import styled from '@emotion/styled'
import Img404 from '@/assets/images/404img.svg'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors['bg-blue']};
  width: 100%;
  height: 100vh;

  .popup-404 {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 617px;
    background-color: ${({ theme: { colors } }) => colors['bg-popup']};
    border-radius: 40px;
    box-sizing: border-box;
    color: ${({ theme: { colors } }) => colors['gray666']};
    font-weight: 400;

    h2 {
      font-weight: 300;
      font-size: 9rem;
      line-height: 110%;
      text-align: center;
      letter-spacing: 0.1em;
      margin-bottom: 30px;
      color: ${({ theme: { colors } }) => colors['black']};
    }

    h3 {
      font-size: 3rem;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.01em;
      margin-bottom: 15px;
    }

    p {
      font-size: 1.6rem;
      line-height: 160%;
      text-align: center;
      letter-spacing: -0.02em;
      margin-bottom: 30px;
    }

    a {
      display: inline-block;
      width: 200px;
      height: 50px;
      box-sizing: border-box;
      padding-top: 15px;
      background: #0f52ff;
      border-radius: 8px;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.01em;
      color: white;
    }
  }

  ::before {
    content: '';
    background-image: url(${Img404});
    background-repeat: no-repeat;
    width: 454px;
    height: 326px;
    display: block;
    position: absolute;
    left: calc(50% - 12px - 55px);
    top: calc(50% - 163px - 244px);
  }
`
