import axios from '.'

export default class AudiossService {
  /** 음성 목록 조회 */
  static async getAudios(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPage?: number
    semantic?: boolean
    or_operator?: boolean
  }) {
    return await axios.get('/audios/', {
      params,
    })
  }

  /** 음성 상세 조회 */
  static async getAudioDetail(audioId: string, searchKeyword: string) {
    return await axios.get(`/audios/${audioId}?keyword=${searchKeyword}`)
  }
}
