// import { useContext } from 'react'
import { CalendarContainer, CalendarContainerProps } from 'react-datepicker'

// import { CalendarContext } from './context'
import styled from '@emotion/styled'

type ContainerProps = CalendarContainerProps & {
  showTimezone?: boolean
  showSelectTodayBtn?: boolean
  showResetBtn?: boolean
  /**
   *
   *
   * @returns `null` or `string`
   */
  handleReturnSelectedValue?: () => null | string
  /**
   *
   */
  handleSelectToday?: () => void
  handleSelectDate?: () => void
  handleResetDate?: () => void
}

export function Container({
  className,
  children,
}: // showResetBtn = false,
// handleSelectDate,
// handleResetDate,
ContainerProps): JSX.Element {
  // ==================== context
  // const { handleOpenClose } = useContext(CalendarContext)
  // ==================== function

  /** `선택` Button의 handler */
  // function onSelectDate(): void {
  //   handleSelectDate && handleSelectDate()

  //   handleOpenClose && handleOpenClose(false)
  // }

  /** `초기화` Button의 handler */
  // function onResetDate(): void {
  //   handleResetDate && handleResetDate()

  //   handleOpenClose && handleOpenClose(false)
  // }

  return (
    <StyledContainer>
      <CalendarContainer className={className}>{children}</CalendarContainer>
      {/* <WrapperBtn>
        {showResetBtn ? (
          <button onClick={onResetDate} type='button'>
            초기화
          </button>
        ) : null}
        <button onClick={onSelectDate} type='button'>
          선택
        </button>
      </WrapperBtn> */}
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.6rem;
  border: 1px solid #d8dbe3;
  box-shadow: 0px 4px 8px 0px #d8dbe3;
  overflow: hidden;
`

// const WrapperBtn = styled.div`
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   margin-top: 1rem;
//   padding: 1rem;
//   border-width: 0px, 1px, 1px, 0px;
//   border-top: 1px solid #d8dbe3;

//   button {
//     padding: 0.9rem 1.3rem;
//     background-color: #fff;
//     border: 1px solid #0075e1;
//     border-radius: 0.6rem;

//     :hover {
//       background-color: #c9e5ff;
//     }

//     :disabled {
//       background-color: #bfc3cf;
//       color: #fff;
//       border-color: #bfc3cf;
//     }

//     color: #0075e1;
//     font-size: 1.4rem;
//     font-weight: 700;
//     line-height: 144%;
//   }
// `
