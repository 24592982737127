import React from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/css'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const LoadingStyle = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 200px;
  border-radius: 8px;

  p {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.6rem;
    letter-spacing: 0.03em;
    color: #c2c2c2;
    text-transform: uppercase;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1.2em;
    height: 1.2em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  .loader {
    background-color: transparent;
    color: #c2c2c2;
    font-size: 10px;
    margin: 7.5px auto 57.5px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`

export function Loading({ ...restProps }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Container {...restProps}>
      <LoadingStyle>
        <div className='loader' />
        <p>loading</p>
      </LoadingStyle>
    </Container>
  )
}

/** 팝업 로딩 */
export function PopupLoading() {
  return (
    <div
      className={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 114px);
      `}
    >
      <Loading />
    </div>
  )
}
