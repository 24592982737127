import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from '@/store'

import { MasonryGrid } from '@egjs/react-grid'

import {
  ProgressiveImg,
  Image,
  PopupLoading,
  A11yHidden,
  Logo,
  Loading,
  SearchResultNumberBox,
  SearchNoResult,
} from '@/components'

import { CommonLayout } from '@/theme'

import useModal from '@/hooks/useModal'
import useObserver from '@/hooks/useResizeObserber'
import { LogoH1 } from '@/layout/Header/Header.styled'
import { useLayer } from '@/hooks'
import { useDropzone } from 'react-dropzone'
import { DropzoneWrapper } from '@/components/SearchBar/SearchBar.styled'
import camera from '@/assets/images/camera.svg'
import file_upload from '@/assets/images/file_upload.svg'
import { useQuery } from '@tanstack/react-query'

import ImagesLensService from '@/services/imageLensService'
import styled from '@emotion/styled'
import { AxiosResponse } from 'axios'
import { imageLensResponseType } from '@/types/imageLensType'
import ImageCrop from '@/components/ImageCrop/ImageCrop'
import { useDispatch } from 'react-redux'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { imagesSagaActions } from '@/store/saga/imagesSaga'

export function ImageLens() {
  /** redux */
  const dispatch = useDispatch()
  const { detailData } = useSelector((state) => state.search)

  /** router */
  const [searchParams] = useSearchParams()

  /* -------------------------------- variables ------------------------------- */
  const [fileUrl, setFileUrl] = useState<string | null>(null)
  const [cropfileUrl, setCropFileUrl] = useState<string | null>(null)

  /* ------------------------------- react-query ------------------------------ */
  const { data, isLoading } = useQuery({
    queryKey: [
      'imageLens',
      cropfileUrl ? { crop: cropfileUrl?.slice(-30) } : { original: fileUrl?.slice(-20) },
    ],
    queryFn: async () => {
      const base64Url = cropfileUrl ? cropfileUrl : fileUrl
      const form = new FormData()

      const res = await fetch(base64Url as string)
      const blob = await res.blob()
      const file = await new File([blob], searchParams.get('filename') as string, {
        type: blob.type,
      })

      form.append('file', file)

      return await ImagesLensService.getImageLens(form)
    },
    select: ({ data }: AxiosResponse<imageLensResponseType>) => {
      dispatch(imagesSagaActions.setImagesSagaAction({ data }))

      return data
    },
    enabled: !!fileUrl,
  })

  useLayoutEffect(() => {
    const imageLensFileUrl = localStorage.getItem('imageLensFileUrl')

    if (imageLensFileUrl) {
      setFileUrl(imageLensFileUrl)
    }
  }, [])

  /** ref */
  const imgItemRef = useRef<HTMLDivElement | null>(null)
  const [targetRef, setTargetRef] = useState<HTMLElement | null>(null)

  /** hooks */
  const { ModalPortal, openModal } = useModal(targetRef)

  /** 팝업 오픈 */
  const handleShowPopup = useCallback(
    (e: React.MouseEvent) => {
      const currentTarget = e.currentTarget as HTMLElement
      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'img' }))
    },
    [dispatch, openModal],
  )

  const handleKeyDownShowPopup = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.code !== 'Enter') return
      const currentTarget = e.currentTarget as HTMLElement

      openModal()
      setTargetRef(currentTarget)
      dispatch(searchSagaActions.setDetailDataAction({ id: currentTarget.id, type: 'img' }))
    },
    [dispatch, openModal],
  )

  /** resize observer */
  const [columnCount, setColumnCount] = useState(5)

  const handle = useCallback((entries: ResizeObserverEntry[]) => {
    const [entry] = entries

    if (entry.contentRect.width > 1200) setColumnCount(5)
    else if (entry.contentRect.width > 995) setColumnCount(4)
    else if (entry.contentRect.width > 791) setColumnCount(3)
    else if (entry.contentRect.width > 587) setColumnCount(2)
    else if (entry.contentRect.width > 383) setColumnCount(1)
    else setColumnCount(1)
  }, [])

  useObserver({ callback: handle, element: 'root' })

  // 이미지 검색
  const navigate = useNavigate()
  const { isShow, handleIsShow } = useLayer()

  // 사용자가 업로드한 파일 정보 확인
  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    if (acceptedFiles.length > 1) {
      window.alert('여러 이미지를 검색할 수 없습니다.')
      return
    }
    localStorage.removeItem('imageLensFileUrl')

    const reader = new FileReader()

    reader.onload = function (e: ProgressEvent<FileReader>) {
      if (e.target) {
        const base64Image = e.target.result

        localStorage.setItem('imageLensFileUrl', base64Image as string)
        navigate(`/imageLens?filename=${acceptedFiles[0].name}`, {
          replace: true,
        })
      }
    }

    reader.readAsDataURL(acceptedFiles[0])

    handleIsShow(false)

    window.location.reload()
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.bmp', '.tif', '.webp'],
    },
    multiple: false,
  })

  const ref = useRef<HTMLDivElement>(null)

  const handler = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement

      // 파일 검색 레이어의 파일 업로드 버튼 클릭 시 layer 닫힘 무시
      if (target.id === 'fileUploadBtn') return

      if (ref.current && !ref.current.contains(target)) {
        handleIsShow(false)
      }
    },
    [handleIsShow],
  )

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        window.addEventListener('click', handler)
      }, 0)
    }

    return () => window.removeEventListener('click', handler)
  }, [handler, isShow])

  return (
    <Container>
      <div className='search_header'>
        <LogoH1>
          <a href='/'>
            <A11yHidden>T3Q.search+</A11yHidden>
            <Logo width={145} />
          </a>
        </LogoH1>
        <div style={{ position: 'relative' }} ref={ref}>
          {/* 이미지 검색 */}
          <ImgSearchBth onClick={() => handleIsShow(true)}>
            <img src={camera} alt='이미지 검색' />
          </ImgSearchBth>
          {isShow && (
            <ImgSearchLayer>
              <DropzoneWrapper {...getRootProps()} $isDragActive={isDragActive}>
                <label htmlFor='fileInput'>파일업로드</label>
                <input id='fileInput' {...getInputProps()} />
                <img width='30' height='30' src={file_upload} alt='이미지 검색' />
                <p>이미지를 드래그 하거나 파일을 업로드 하세요</p>
                <br />
                (한 번에 하나의 이미지 파일만 업로드 가능합니다)
              </DropzoneWrapper>
              <button id='fileUploadBtn' className='submit_btn' type='button' onClick={open}>
                파일 업로드
              </button>
            </ImgSearchLayer>
          )}
        </div>
      </div>

      <div className='search_container'>
        <div className='search_lens_img_wrapper'>
          <div className='img_wrapper'>
            <ImageCrop imgSrc={fileUrl as string} setCropFileUrl={setCropFileUrl} />
          </div>
        </div>

        <div className='search_result_img_wrapper'>
          <div className='search_result_inner'>
            {isLoading ? (
              <Loading />
            ) : data?.data.length !== 0 ? (
              <>
                {/* <SearchResultNumberBox count={data?.meta.count ?? 0} /> */}
                {data?.data[0].related_word && (
                  <StyleSearchResultNumberBox>
                    <span className='img_title'>{data?.data[0].related_word}</span>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <a
                        href={`/search/?keyword=${data?.data[0].related_word}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        검색
                      </a>
                    </div>
                    {/* <hr /> */}
                  </StyleSearchResultNumberBox>
                )}
                <MasonryGrid
                  className='img_grid_wrapper'
                  column={columnCount}
                  gap={24}
                  align='stretch'
                  useResizeObserver
                  observeChildren
                >
                  {data?.data.map((img, index) => (
                    <div
                      className='image_masonry__column'
                      key={`${index}-${img.id}`}
                      id={img.id}
                      ref={imgItemRef}
                      tabIndex={0}
                      onClick={handleShowPopup}
                      onKeyDown={handleKeyDownShowPopup}
                    >
                      <ProgressiveImg
                        id={img.id}
                        src={img.file}
                        placeholderSrc={''}
                        alt={`${searchParams.get('keyword')} 관련 이미지(${index + 1})`}
                        width='100%'
                      />
                    </div>
                  ))}
                </MasonryGrid>
              </>
            ) : (
              <>
                <SearchResultNumberBox count={0} />
                <div className='search_result_inner'>
                  {/* <div style={{ width: '792px' }}> */}
                  <div>
                    <SearchNoResult sectionClassName='img' />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* {!isEnd && (
              <div className='btn_box'>
                <ViewMoreButton
                
              </div>
            )} */}
      </div>

      <ModalPortal id={'modal_portal'}>{detailData ? <Image /> : <PopupLoading />}</ModalPortal>
    </Container>
  )
}

const StyleSearchResultNumberBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 17px;
  padding: 18px 0 17px 0;

  border-bottom: 1px solid rgba(225, 225, 225, 1);

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4rem;

  strong {
    font-weight: 700;
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: rgba(225, 225, 225, 1);
    margin-top: 0.6rem;
    border: none;
  }

  .img_title {
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    border: 1px solid #0f52ff;
    border-radius: 0.8rem;

    font-size: 1.4rem;
    font-weight: 500;
    line-height: 144%;
    letter-spacing: -0.01em;
    color: #0f52ff;

    &:hover {
      background-color: rgba(15, 82, 255, 0.1);
    }

    &:active {
      background-color: rgba(15, 82, 255, 0.3);
    }
  }
`

export const ImgSearchBth = styled.button`
  width: 32px;
  height: 32px;
`

export const ImgSearchLayer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  width: min(50vw, 636px);
  height: 270px;

  border-radius: 12px;
  box-shadow: 0px 35px 50px 0px #0000001f;
  background-color: #fff;

  .submit_btn {
    align-self: center;

    display: inline-block;
    width: 200px;
    height: 50px;
    box-sizing: border-box;
    background: #0f52ff;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.01em;
    color: white;
  }
`

const Container = styled(CommonLayout)`
  /* min-height: calc(100vh - 317px); */
  max-width: initial;

  display: flex;
  flex-direction: column;

  .search_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 25px 20px;
    border-bottom: 1px solid #e1e1e1;
  }

  .search_container {
    display: flex;
  }

  /* 이미지 렌즈 영역 */
  .search_lens_img_wrapper {
    flex: 4;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: calc(100vh - 83px);
    padding: 24px;
    background-color: #666;

    img {
      width: initial;
      max-width: 100%;
      height: auto;
    }
  }

  .search_result_img_wrapper {
    flex: 6;

    display: flex;
    flex-flow: column nowrap;

    width: 100%;
    padding: 24px 16px 0;
    max-height: calc(100vh - 90px);
    overflow-y: auto;
  }

  /* 검색 결과 영역 */
  .search_result_inner {
    width: 100%;
    padding-bottom: 100px;
  }

  .img_grid_wrapper {
    & + .btn_box {
      margin-top: 40px;
    }
  }

  .grid_item {
    max-width: 180px;
    width: 100%;
  }

  .image_masonry__column {
    position: relative;
    width: 180px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    figure {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:focus::after,
    &:hover::after {
      cursor: pointer;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }

  .btn_box {
    width: 100%;
    text-align: center;

    .bth_viewMoer {
      width: min(100%, 792px);
    }
  }
`
