export function checkFileExtention(file: string, extensionArr: string[]) {
  let state = ''

  extensionArr.forEach((ex) => {
    if (file.includes(ex)) return (state = ex)

    return state
  })

  return state
}
