import { css } from '@emotion/react'
import styled from '@emotion/styled'

interface WrapperProps {
  isCurrent?: boolean
}

export const Wrapper = styled.button<WrapperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 1.4rem;
  box-sizing: border-box;
  color: ${({ isCurrent, theme: { colors } }) => (isCurrent ? colors['blue'] : colors['gray'])};

  :focus {
    outline: 2px solid ${({ theme: { colors } }) => colors['gray']};
  }

  ${({ isCurrent, theme: { colors } }) => {
    if (!isCurrent) return ''
    return css`
      ::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: ${colors['blue']};
        position: absolute;
        left: 0;
        bottom: 0;
      }
    `
  }};
`
