import React from 'react'
import ReactDOM from 'react-dom/client'
import { unstable_HistoryRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import store, { history } from './store'
import App from './App'
import { AppThemeContainer } from './theme/AppThemeContainer'

import keycloak_config from './Keycloak'
import { QueryClientProvider } from '@tanstack/react-query'
import { getQueryClient } from './services/query/getQueryClient'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ReactKeycloackIdProvider } from 'react-keycloak-id'

// login-required vs check-sso
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ReactKeycloackIdProvider init={keycloak_config} initOptions={{ onLoad: 'check-sso' }}>
    {/* <React.StrictMode> */}
      <QueryClientProvider client={getQueryClient()}>
        <Provider store={store}>
          <AppThemeContainer>
            <Router history={history}>
              <App />
            </Router>
          </AppThemeContainer>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    {/* </React.StrictMode> */}
  </ReactKeycloackIdProvider>,
)
