import React, { ReactNode } from 'react'
import * as S from './TabItem.styled'
import { useNavTabContext } from './useNavTabContext'
import { useNavigate } from 'react-router-dom'

interface TabItemProps {
  children: ReactNode
  to: string
}

export function TabItem({ children, to }: TabItemProps): JSX.Element {
  const { currentTab, pathBase } = useNavTabContext()
  const navigate = useNavigate()

  return (
    <S.Wrapper
      onClick={() => {
        navigate(`${pathBase}${to}${window.location.search}`)
      }}
      isCurrent={to == currentTab}
    >
      {children}
    </S.Wrapper>
  )
}
