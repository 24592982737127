import React, { useState } from 'react'
import * as S from './OrSearchCheck.styled'

import { ReactComponent as Help } from '@/assets/svg/help.svg'
import { A11yHidden } from '@/components'

interface OrSearchCheckProps extends React.HTMLAttributes<HTMLDivElement> {
  setChangedValue?(value: boolean): void
  isGray?: boolean
  label?: string
  initialValue?: boolean
}

export function OrSearchCheck({
  setChangedValue,
  isGray = false,
  label = '검색 결과 확장',
  initialValue = false,
  ...restProps
}: OrSearchCheckProps): JSX.Element {
  const [checked, setChecked] = useState(initialValue)

  return (
    <S.Wrapper {...restProps}>
      <input
        type='checkbox'
        id='and-only-search-checkbox'
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked)
          setChangedValue && setChangedValue(e.target.checked)
        }}
        onKeyDown={(e) => {
          if (e.key != 'Enter' && e.key != ' ') return
          setChecked(!checked)
          setChangedValue && setChangedValue(!checked)
        }}
      />
      <S.Label htmlFor='and-only-search-checkbox' isGray={isGray} checked={checked}>
        <span>{label}</span>
      </S.Label>
      <S.Tootip $isGray={isGray} title='도움말'>
        <A11yHidden>도움말</A11yHidden>
        <Help />
        <div className='wrap_tooltip' style={{ whiteSpace: 'pre-wrap' }}>
          <strong>
            입력한 검색 문구(최소 2단어 이상)중 하나 이상을 포함한 주제의 컨텐츠를 찾아줍니다.
          </strong>
          <p>
            ex) &#39;코로나바이러스 발생 현황&#39;으로 검색 시 &#39;코로나바이러스 발생 현황 을 하나
            이상 포함한 컨텐츠를 찾아줍니다.
          </p>
        </div>
      </S.Tootip>
    </S.Wrapper>
  )
}
