import React from 'react'
import * as S from './404.styled'
import { Link } from 'react-router-dom'

export function Page404(): JSX.Element {
  return (
    <S.Wrapper>
      <div className='popup-404'>
        <h2>404</h2>
        <h3>요청한 페이지를 찾을 수 없습니다.</h3>
        <p>홈 페이지로 이동해 이용 가능한 페이지를 탐색해보세요.</p>
        <Link to='/'>메인으로 돌아가기</Link>
      </div>
    </S.Wrapper>
  )
}
