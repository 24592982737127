import React, { useCallback, useState } from 'react'

import * as S from './Footer.styled'

import { ReactComponent as Facebook } from '@/assets/svg/facebook.svg'
import { ReactComponent as YouTube } from '@/assets/svg/youtube.svg'
import useModal from '@/hooks/useModal'

export function Footer(): JSX.Element {
  const [targetRef, serTargetRef] = useState<HTMLElement | null>(null)

  const { ModalPortal, openModal } = useModal(targetRef)

  const handleOpenVersionModal = useCallback(
    (e: React.MouseEvent) => {
      const target = e.currentTarget as HTMLButtonElement
      openModal()
      serTargetRef(target)
    },
    [openModal],
  )

  return (
    <S.Footer>
      <div>
        <div className='area_info'>
          <address>
            <span>(30121) 서울특별시 구로구 디지털로 30길 28 마리오타워 601~605, 618호</span>
            <span className='address_tel'>
              대표번호 : <a href='tel:0263447660'>02-6344-7660</a>
            </span>
            <span className='address_fax'>
              FAX : <a href='tel:0263447661'>02-6344-7661</a>
            </span>
          </address>
          <div className='txt_copy'>Copyright &copy; 2021 T3Q All rights reserved.</div>
        </div>
        <div className='button_box'>
          <button type='button' onClick={handleOpenVersionModal}>
            Version 3.0
          </button>
        </div>
        <div className='area_shortcut'>
          <a href='https://www.facebook.com/t3qai' target='_blank' rel='noopener noreferrer'>
            <Facebook className='icon_facebook' />
          </a>
          <a
            href='https://www.youtube.com/channel/UC-8rGWYgHAZNXSuQEAG5uxQ'
            target='_blank'
            rel='noopener noreferrer'
            className='icon_youtube'
          >
            <YouTube />
          </a>
        </div>
      </div>
      <ModalPortal id={'version_potal'}>
        <S.VersionPopupWrapper>
          <div className='heading_box'>
            <p className='title'>About Version</p>
          </div>
          <div className='contents'>
            <div className='solution_box'>
              <span className='solution_name'>T3Q.Search</span>
              <span>Version 3</span>
            </div>
            <div className='compoents_box'>
              <span className='components_name'>Components</span>
              <ul>
                <li>
                  <span>T3Q.cep</span>
                  <span>V3</span>
                </li>
                <li>
                  <span>T3Q.dl</span>
                  <span>V3</span>
                </li>
                <li>
                  <span>T3Q.pms</span>
                  <span>V3</span>
                </li>
                <li>
                  <span>T3Q.search+</span>
                  <span>V3</span>
                </li>
              </ul>
            </div>
          </div>
        </S.VersionPopupWrapper>
      </ModalPortal>
    </S.Footer>
  )
}
