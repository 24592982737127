import React from 'react'
import * as S from './NavTabIcon.styled'
import { useNavTabContext } from './useNavTabContext'

interface NavTabIconProps {
  to: string
}

export function NavTabIcon({ to }: NavTabIconProps) {
  const { currentTab } = useNavTabContext()

  return <S.Wrapper to={to} isCurrent={to == currentTab} />
}
