import { createAction } from 'typesafe-actions'
import { put, select, call, takeLatest } from 'redux-saga/effects'
import { RootState } from '..'

import { audiosActions } from './../audios'
import { searchActions } from '../search'
import { audiosType, audiosDataType } from '@/types/audiosType'

import AudiosService from '@/services/AudiosService'

const prefix = 'audios'

const getAudiosSagaAction = createAction(`${prefix}/GET_AUDIOS_SAGA_ACTION`)<
  Record<string, string>
>()

function* getAudiosSaga({ payload }: ReturnType<typeof getAudiosSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus } = yield select(
    (state: RootState) => state.search,
  )

  const audios: audiosDataType[] | null = yield select((state: RootState) => state.audios.audios)

  try {
    if (audios && audios.length !== 0) yield put(audiosActions.reset('audios'))

    const { keyword, s_event_date, e_event_date } = payload

    const { data }: { data: audiosType } = yield call(AudiosService.getAudios, {
      keyword,
      s_event_date,
      e_event_date,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
    })

    yield put(audiosActions.getAudios(data))
    yield put(searchActions.setSearchQuery(payload))
  } catch (e) {
    yield put(audiosActions.error('audios'))
  }
}

// 비디오 페이지네이션
const getNextAudiosSagaAction = createAction(`${prefix}/GET_NEXT_AUDIOS_SAGA_ACTION`)<number>()

function* getNextAudiosSaga({ payload }: ReturnType<typeof getNextAudiosSagaAction>) {
  const { orSearchCheckStatus, semanticSearchCheckStatus, searchQuery } = yield select(
    (state: RootState) => state.search,
  )

  try {
    const page = payload

    const { data } = yield call(AudiosService.getAudios, {
      ...searchQuery,
      paging: page,
      semantic: semanticSearchCheckStatus,
      or_operator: orSearchCheckStatus,
    })

    yield put(audiosActions.getNextAudios({ response: data, pagingNumber: page }))
  } catch (e) {
    yield put(audiosActions.error(e))
  }
}

export const audiosSagaActions = {
  getAudiosSagaAction,
  getNextAudiosSagaAction,
}

export default function* audiosSaga() {
  yield takeLatest(getAudiosSagaAction, getAudiosSaga)
  yield takeLatest(getNextAudiosSagaAction, getNextAudiosSaga)
}
