/* eslint camelcase: 0 */

import React from 'react'
import * as S from './Pagination.styled'
import _Pagination from 'rc-pagination'

interface PaginationProps {
  currentPage: number
  setCurrent(page: number): void
  perPage: number
  totalRecordCount: number
}

export function Pagination({
  currentPage,
  setCurrent,
  perPage,
  totalRecordCount,
}: PaginationProps): JSX.Element {
  return (
    <S.Wrapper>
      <_Pagination
        current={currentPage}
        onChange={(current) => {
          setCurrent(current)
        }}
        pageSize={perPage}
        total={totalRecordCount}
        itemRender={(current, type, element) => {
          if (type === 'prev') {
            return (
              <button tabIndex={1} type='button'>
                <span className='a11y'>prev</span>
              </button>
            )
          }
          if (type === 'next') {
            return (
              <button tabIndex={1} type='button'>
                <span className='a11y'>next</span>
              </button>
            )
          }
          if (type === 'jump-prev') {
            return (
              <button tabIndex={1} type='button'>
                ...
              </button>
            )
          }
          if (type === 'jump-next') {
            return (
              <button tabIndex={1} type='button'>
                ...
              </button>
            )
          }
          if (type === 'page') {
            return (
              <button tabIndex={1} type='button'>
                {current}
              </button>
            )
          }
          return element
        }}
        showPrevNextJumpers={true}
        locale={{
          // Options.jsx
          items_per_page: '/ 쪽',
          jump_to: '이동하기',
          jump_to_confirm: '확인하다',
          page: '페이지',
          // Pagination.jsx
          prev_page: '이전 페이지',
          next_page: '다음 페이지',
          prev_5: '이전 5 페이지',
          next_5: '다음 5 페이지',
          prev_3: '이전 3 페이지',
          next_3: '다음 3 페이지',
        }}
      ></_Pagination>
    </S.Wrapper>
  )
}
