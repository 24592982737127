import { Global, css } from '@emotion/react'

export const GlobalStyle = (): JSX.Element => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 100;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');
        }

        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 300;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');
        }

        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 400;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');
        }

        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 500;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');
        }

        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 700;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');
        }

        @font-face {
          font-family: 'Noto Sans KR';
          font-style: normal;
          font-weight: 900;
          src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),
            url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');
        }

        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          font-family: inherit;
          font-style: normal;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
          letter-spacing: 0em;
          text-decoration: inherit;
          color: inherit;
          vertical-align: top;
        }

        a,
        address,
        area,
        article,
        aside,
        audio,
        b,
        body,
        br,
        button,
        canvas,
        caption,
        cite,
        code,
        col,
        colgroup,
        dd,
        del,
        div,
        dl,
        dt,
        em,
        embed,
        fieldset,
        figcaption,
        figure,
        footer,
        form,
        frame,
        frameset,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        head,
        header,
        hr,
        html,
        i,
        iframe,
        img,
        ins,
        input,
        label,
        legend,
        li,
        link,
        map,
        meta,
        nav,
        object,
        ol,
        p,
        pre,
        q,
        rp,
        rt,
        ruby,
        s,
        script,
        section,
        select,
        source,
        span,
        strong,
        style,
        sub,
        summary,
        sup,
        table,
        tbody,
        td,
        textarea,
        tfoot,
        th,
        thead,
        time,
        title,
        tr,
        u,
        ul,
        video {
          margin: 0;
          padding: 0;
          font-family: inherit;
          font-style: normal;
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
          text-decoration: inherit;
          color: inherit;
          vertical-align: top;
        }

        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }

        html {
          width: 100%;
          height: 100%;
          font-weight: 300;
          font-size: 10px;
          color: #222222;
          line-height: 1.2;
          -ms-touch-action: manipulation;
          touch-action: manipulation;
        }

        body {
          background-color: #f8f8fc;
          margin: 0;
        }

        body,
        button,
        input,
        select,
        td,
        textarea,
        th {
          font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', 'Malgun Gothic', '맑은 고딕',
            sans-serif;
        }

        body,
        body *,
        body *::before,
        body *::afoter {
          box-sizing: border-box;
          transition: all 0.2s ease;
        }

        a {
          text-decoration: none;
        }

        button {
          cursor: pointer;
          border: none;
          padding: 0;
          background-color: inherit;
        }

        abbr[title] {
          cursor: help;
          text-decoration: none;
        }

        ol,
        ul {
          list-style: none;
        }

        li {
          list-style: none;
        }

        blockquote,
        q {
          quotes: none;
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }

        img {
          width: 100%;
        }
      `}
    />
  )
}
