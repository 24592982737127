import styled from '@emotion/styled'
import { CommonLayout } from '@/theme'

export const Container = styled(CommonLayout)`
  display: block;
  width: 100%;
  padding: 28px 30px 29px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

  ul {
    list-style: none;
    margin-top: 10px;
    li {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 200%;
      letter-spacing: -0.01em;

      ::before {
        content: '·';
        margin-right: 5px;
      }
    }
  }

  h1,
  h2,
  h3 {
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: -0.03em;

    strong {
      color: ${({ theme: { colors } }) => colors['red']};
      ::before {
        content: '"';
      }

      ::after {
        content: '"';
      }
    }
  }
`
