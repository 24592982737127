import styled from '@emotion/styled'

/* -------------------------------------------------------------------------- */
/* DESIGN TOKENS                                                              */
/* -------------------------------------------------------------------------- */

/* COLORS ------------------------------------------------------------------- */

const colors = {
  blue: '#0f52ff',
  black: '#222222',
  gray: '#999999',
  gray666: '#666666',
  red: '#FF1111',
  'bg-blue': '#EAECF7',
  'bg-popup': '#F3F5FC',
  'focused-bg': '#f8f8fc',
}

/* LAYOUT ------------------------------------------------------------------- */

const layout = {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  maxWidth: 1376,
}

/* A11Y --------------------------------------------------------------------- */

export const a11y = {
  textHidden: `overflow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  clip-path: circle(0);
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;

  /* 화면에 a11y 요소가 보이지 않더라도 포커스 되었을 때 보이기 때문에 포커스 상태에도 감춰질 수 있도록 한다. */
  &:focus {
    overflow: inherit;
    position: inherit;
    clip: inherit;
    clip-path: inherit;
    width: inherit;
    margin: inherit;
    border: inherit;
    padding: inherit;
    white-space: inherit;
  }
`,
}

/* -------------------------------------------------------------------------- */
/* COMMON TOKEN                                                               */
/* -------------------------------------------------------------------------- */
export const commonTokens = {
  layout,
  colors,
}

export const CommonLayout = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

export type ThemeTypes = typeof commonTokens
