import { Provider } from './context'

// import { calendarContainerStyle } from './style.css';

type RootProps = React.HTMLAttributes<HTMLDivElement>

export function Root({ children, ...props }: RootProps): JSX.Element {
  return (
    <Provider>
      <div {...props}>{children}</div>
    </Provider>
  )
}
