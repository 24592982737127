import { addDays, format, formatDate, subDays } from 'date-fns'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import * as Calendar from '../Calendar'

import styled from '@emotion/styled'
import { ReactComponent as CalendarIcon } from '@/assets/svg/calendar.svg'
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg'
import { ReactComponent as CancelIcon } from '@/assets/svg/cancel.svg'
import { useDispatch } from 'react-redux'
import { searchActions } from '@/store/search'
import { useSelector } from '@/store'

// 날짜 범위 선택 및 검색 버튼을 포함하는 컴포넌트
type DateRangePickerProps = {
  openCloseHandler: () => void
}

const DateRangePicker = ({ openCloseHandler }: DateRangePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const { searchQuery } = useSelector((state) => state.search)

  // ==================== 날짜 선택
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const startDate = searchParams.get('s_event_date')

    return startDate ? new Date(startDate) : null
  })
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const endDate = searchParams.get('e_event_date')

    return endDate ? new Date(endDate) : null
  })

  /** 시작일시 선택 function */
  const onSelectStartDate = useCallback(
    (date: Date | null): void => {
      setStartDate(date)
      dispatch(
        searchActions.setSearchQuery({
          // keyword,
          s_event_date: date ? format(date, 'yyyy-MM-dd') : '',
        }),
      )
    },
    [dispatch],
  )

  /** 종료일시 선택 function */
  const onSelectEndDate = useCallback(
    (date: Date | null): void => {
      setEndDate(date)

      dispatch(
        searchActions.setSearchQuery({
          // keyword,
          e_event_date: date ? format(date, 'yyyy-MM-dd') : '',
        }),
      )
    },
    [dispatch],
  )

  const handleSearchClick = useCallback(() => {
    // const keyword = searchParams.get('keyword') as string
    const { keyword } = searchQuery

    const params: Record<string, string> = {
      keyword,
    }

    if (startDate && !endDate) {
      params['s_event_date'] = formatDate(startDate, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(new Date(), 'yyyy-MM-dd')
    }

    if (!startDate && endDate) {
      params['s_event_date'] = formatDate('1900-01-01', 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(endDate, 'yyyy-MM-dd')
    }

    if (startDate && endDate) {
      params['s_event_date'] = formatDate(startDate, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(endDate, 'yyyy-MM-dd')
    }

    setSearchParams(params)
  }, [endDate, searchQuery, setSearchParams, startDate])

  //
  const resetHandler = useCallback(() => {
    setStartDate(null)
    setEndDate(null)

    const keyword = searchParams.get('keyword') as string

    dispatch(
      searchActions.setSearchQuery({
        keyword,
        s_event_date: '',
        e_event_date: '',
      }),
    )
  }, [dispatch, searchParams])

  return (
    <ContainerDateRangePicker className='date-range-picker'>
      <span className='title'>기간 검색</span>
      <button type='button' className='close-btn' onClick={openCloseHandler}>
        <CloseIcon />
      </button>
      <div className='date-input-container'>
        {/* e_ */}
        <WrapperCalendar className='wrapper-calendar'>
          <Calendar.Root style={{ flexGrow: 1 }}>
            <Calendar.DatePicker
              {...{
                selected: startDate,
                onChange: onSelectStartDate,
                maxDate: endDate ? subDays(new Date(endDate), 0) : new Date(),
                customInput: (
                  <CustomCalendarInput>
                    <CalendarIcon />
                    <span>{startDate ? format(startDate, 'yyyy.MM.dd') : ''}</span>
                  </CustomCalendarInput>
                ),
                calendarContainer: (props) => <Calendar.Container {...props} />,
              }}
            />
          </Calendar.Root>
          {startDate ? (
            <button
              type='button'
              className='date-reset-btn'
              onClick={() => {
                setStartDate(null)
                dispatch(
                  searchActions.setSearchQuery({
                    // keyword,
                    s_event_date: '',
                  }),
                )
              }}
            >
              <CancelIcon />
            </button>
          ) : null}
        </WrapperCalendar>

        <span>~</span>

        <WrapperCalendar className='wrapper-calendar'>
          <Calendar.Root style={{ flexGrow: 1 }}>
            <Calendar.DatePicker
              {...{
                selected: endDate,
                onChange: onSelectEndDate,
                minDate: startDate ? addDays(new Date(startDate), 0) : undefined,
                maxDate: new Date(),
                customInput: (
                  <CustomCalendarInput>
                    <CalendarIcon />
                    <span>{endDate ? format(endDate, 'yyyy.MM.dd') : ''}</span>
                  </CustomCalendarInput>
                ),
                calendarContainer: (props) => <Calendar.Container {...props} />,
              }}
            />
          </Calendar.Root>
          {endDate ? (
            <button
              type='button'
              className='date-reset-btn'
              onClick={() => {
                setEndDate(null)
                dispatch(
                  searchActions.setSearchQuery({
                    e_event_date: '',
                  }),
                )
              }}
            >
              <CancelIcon />
            </button>
          ) : null}
        </WrapperCalendar>
      </div>
      <WrapperBtn>
        <ResetButton className='reset-button' onClick={resetHandler}>
          초기화
        </ResetButton>
        <SearchButton className='search-button' onClick={handleSearchClick}>
          검색
        </SearchButton>
      </WrapperBtn>
    </ContainerDateRangePicker>
  )
}

export default DateRangePicker

const ContainerDateRangePicker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  position: relative;
  background-color: #fff;
  margin-top: 2.5rem;
  padding: 2rem;
  border-radius: 0.6rem;

  .title {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 100%;
  }

  .close-btn {
    position: absolute;
    top: 1.1rem;
    right: 1.3rem;

    padding: 0.6rem;
    border-radius: 0.6rem;

    :hover {
      background-color: #f0f2f8;
    }
  }

  .date-input-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 0.8rem;
  }
`

const WrapperCalendar = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  width: 50%;
  height: 4.4rem;

  .date-reset-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    svg {
      color: #acb1c0;
    }
  }

  &:nth-of-type(1) {
    padding-left: 1rem;
    padding-right: 2.4rem;
  }

  &:nth-of-type(2) {
    padding-right: 3.4rem;

    .date-reset-btn {
      right: 1rem;
    }
  }
`

const CustomCalendarInput = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  cursor: pointer;

  span {
    width: 100%;
  }
`

const WrapperBtn = styled.div`
  text-align: end;

  button + button {
    margin-left: 0.6rem;
  }
`

const SearchButton = styled.button`
  width: fit-content;
  padding: 0.9rem 1.3rem;
  border-radius: 0.6rem;

  background: #0075e1;
  color: #fff;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 144%;

  :hover {
    background-color: #053ba4;
  }
`
const ResetButton = styled.button`
  width: fit-content;
  padding: 0.9rem 1.3rem;
  border: 1px solid #5a6073;
  border-radius: 0.6rem;

  background: #fff;
  color: #5a6073;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 144%;

  :hover {
    background-color: #f0f2f8;
  }
`
