import { DefaultOptions, QueryClient } from '@tanstack/react-query'

/**
 * React Query의 쿼리 클라이언트 인스턴스를 반환하는 함수 팩토리입니다.
 * @param defaultOptions - defaultOptions QueryClient 생성 시 사용할 기본 옵션 객체
 * @returns QueryClient
 */
export const createGetQueryClient = (
  // defaultOptions을 받아서 생성하는 이유는 프로젝트마다 요구사항이 다를 수 있기 때문.
  defaultOptions: DefaultOptions = {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      throwOnError: true,
    },
  },
): (() => QueryClient) => {
  let queryClient: QueryClient

  return function getQueryClient() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!queryClient) {
      queryClient = new QueryClient({ defaultOptions })
    }

    return queryClient
  }
}

// 커스텀 옵션의 니즈가 없는 경우 미리 생성된 getQueryClient 를 사용할 수도 있음.
export const getQueryClient = createGetQueryClient()
