import React from 'react'
import * as S from './SectionTitle.styled'

interface SectionTitleProps {
  title: '문서' | '이미지' | '동영상' | '음성' | '유사문서'
}

export function SectionTitle({ title }: SectionTitleProps): JSX.Element {
  return (
    <S.Wrapper title={title} as='h2'>
      <span>{title}</span>
    </S.Wrapper>
  )
}
