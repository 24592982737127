import React from 'react'

import styled from '@emotion/styled'

import { ReactComponent as Print } from '@/assets/svg/print.svg'
import { ReactComponent as Download } from '@/assets/svg/file-download.svg'

const ButtonStyle = styled.button`
  padding: 0 9px;
  border: solid 1px #e1e1e1;
  border-radius: 4px;
  height: 28px;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: -0.03em;
  color: #666;
  background-color: #ae2f2f;

  &:hover {
    background-color: #666;
    border-color: #0f52ff;
    color: #0f52ff;
  }

  svg {
    margin-right: 5px;
  }
`

export interface ButtonWithIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string
  iconType: string
}

export function ButtonWithIcon({ label, iconType, ...restProps }: ButtonWithIconProps) {
  return (
    <ButtonStyle type='button' title={label} {...restProps}>
      <SvgIcon iconType={iconType} />
      {label}
    </ButtonStyle>
  )
}

function SvgIcon({ iconType }: { iconType: string }) {
  switch (iconType) {
    default:
    case 'print':
      return <Print />
    case 'download':
      return <Download />
  }
}
