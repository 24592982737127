import { useCallback, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'

/**
 * 레이어 기능을 제공하는 custom Hook.
 *
 * `useLayer` custom hook은 `{ Layer, layerRef, isShow, handleIsShow }` 을 리턴합니다.
 *
 * `layerRef`는 레이어 기능을 사용하려는 전체 요소를 감싼 `div` 태그의 ref로 설정해줘야 합니다.
 *
 * `Layer`는 `layerRef`가 적용된 컴포넌트입니다.
 *
 * `isShow`는 레이어에 나타나는 요소를 보이게 하는 옵션입니다.
 *
 * `handleIsShow`는 `isShow`를 true/false 하는 `setIsShow` 함수를 갖고 있는 함수입니다.
 *
 * @return Layer, layerRef, isShow, handleIsShow
 */
export const useLayer = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [isShow, setIsShow] = useState(false)

  const handleIsShow = useCallback((state: boolean) => {
    setIsShow(state)
  }, [])

  const handler = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement

    // 파일 검색 레이어의 파일 업로드 버튼 클릭 시 layer 닫힘 무시
    if (target.id === 'fileUploadBtn') return

    if (ref.current && !ref.current.contains(target)) {
      setIsShow(false)
    }
  }, [])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        window.addEventListener('click', handler)
      }, 0)
    }

    return () => window.removeEventListener('click', handler)
  }, [handler, isShow])

  const Layer = ({ children }: { children: React.ReactNode }) => {
    return <LayerContainer ref={ref}>{children}</LayerContainer>
  }

  return { Layer, layerRef: ref, handleIsShow, isShow }
}

const LayerContainer = styled.div`
  position: relative;
  display: inline-flex;
`
