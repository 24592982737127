import { useState, useEffect, useRef } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import Downshift from 'downshift'
import { useDispatch } from 'react-redux'

import { db } from '@/db/RecentlySearchedKeywordDB'
import { OrSearchCheck, SemanticSearchCheck } from '@/components'

import { RootState, useSelector } from '@/store'
import { searchSagaActions } from '@/store/saga/searchSaga'
import { documentsSagaActions } from '@/store/saga/documentsSaga'
import { imagesSagaActions } from '@/store/saga/imagesSaga'
import { videosSagaActions } from '@/store/saga/videosSaga'
import { audiosSagaActions } from '@/store/saga/audiosSaga'

import * as S from './HeaderSearchBar.styled'
import { formatDate } from 'date-fns'
import { searchActions } from '@/store/search'

export function HeaderSearchBar(): JSX.Element {
  /* ----------------------------------- ref ---------------------------------- */
  const inputRef = useRef<HTMLInputElement | null>(null)

  /* -------------------------------- variables ------------------------------- */
  // router
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  // 검색어
  const [searchText, setSearchText] = useState<string>(
    useSelector((state: RootState) => state.search.searchText),
  )

  const [semanticSearch, setSemanticSearch] = useState(
    useSelector((state: RootState) => state.search.semanticSearchCheckStatus),
  )
  const [orSearch, setOrSearch] = useState(
    useSelector((state: RootState) => state.search.orSearchCheckStatus),
  )

  const { searchQuery } = useSelector((state) => state.search)

  /* ---------------------------------- redux --------------------------------- */
  const items = useSelector((state) => state.search.acList)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(searchSagaActions.searchAcSagaAction(searchText))
    dispatch(searchSagaActions.setSearchTextSagaAction(searchText))
  }, [searchText, dispatch])

  useEffect(() => {
    dispatch(searchSagaActions.setSemanticSearchCheckAction(semanticSearch))
  }, [semanticSearch, dispatch])

  useEffect(() => {
    dispatch(searchSagaActions.setOrSearchCheckAction(orSearch))
  }, [orSearch, dispatch])

  const onSubmit = () => {
    const { keyword, s_event_date, e_event_date } = searchQuery

    const params: Record<string, string> = {
      keyword,
    }

    if (s_event_date && !e_event_date) {
      params['s_event_date'] = formatDate(s_event_date, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(new Date(), 'yyyy-MM-dd')
    }

    if (!s_event_date && e_event_date) {
      params['s_event_date'] = formatDate('1900-01-01', 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(e_event_date, 'yyyy-MM-dd')
    }

    if (s_event_date && e_event_date) {
      params['s_event_date'] = formatDate(s_event_date, 'yyyy-MM-dd')
      params['e_event_date'] = formatDate(e_event_date, 'yyyy-MM-dd')
    }

    if (keyword === null) return

    if (keyword === searchText) {
      const path = location.pathname.split('search/')[1]

      switch (path) {
        case 'document':
          dispatch(documentsSagaActions.setDocumentsSagaAction(params))
          break
        case 'image':
          dispatch(imagesSagaActions.getImagesSagaAction(params))
          break
        case 'video':
          dispatch(videosSagaActions.getVideosSagaAction(params))
          break
        case 'audio':
          dispatch(audiosSagaActions.getAudiosSagaAction(params))
          break
        default:
          dispatch(searchSagaActions.getSearchAllSagaAction(params))
      }
    }

    params.keyword = searchText.trim()

    searchText.trim() && db.add(searchText)

    setSearchParams(params)
  }

  useEffect(() => {
    const keyword = searchParams.get('keyword')

    if (keyword === null) return

    setSearchText(keyword)
  }, [searchParams])

  return (
    <S.Container>
      <S.SearchBarWrapper>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <Downshift
              onChange={async (selection) => {
                setSearchText(selection)
                db.add(selection)
                setSearchParams({
                  keyword: selection,
                })
              }}
              inputValue={searchText}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                getToggleButtonProps,
                isOpen,
                highlightedIndex,
                getRootProps,
                inputValue,
              }) => (
                <S.SearchBar {...getRootProps()}>
                  <S.SearchBarInner>
                    <S.SearchBarInputGroup>
                      <input
                        ref={inputRef}
                        {...getInputProps({
                          onSelect: () => {
                            setSearchText('' + inputValue)
                          },
                          onChange: (e) => {
                            setSearchText(e.target.value)
                          },
                          value: searchText,
                        })}
                        onBlur={() => {
                          if (!inputRef.current) return

                          dispatch(
                            searchActions.setSearchQuery({
                              keyword: inputRef.current.value.trim(),
                            }),
                          )
                        }}
                      />
                      <S.InputButton
                        title='del'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault()
                          setSearchText('')
                          inputRef.current?.focus()
                        }}
                      >
                        <span className='a11y'>입력된 검색어 지우기</span>
                      </S.InputButton>
                      <S.InputButton
                        title='arrow'
                        isRotate180={isOpen}
                        type='button'
                        aria-label='toggle menu'
                        {...getToggleButtonProps()}
                      >
                        <span className='a11y'>자동완성 목록 열기/닫기</span>
                      </S.InputButton>
                      <S.InputButton
                        title='search'
                        type='button'
                        onClick={(e) => {
                          e.preventDefault()
                          onSubmit()
                        }}
                      >
                        <span className='a11y'>검색하기</span>
                      </S.InputButton>
                      {/* <S.InputButton
                        title='mic'
                        type='button'
                        onClick={() => {
                          return
                        }}
                      >
                        <span className='a11y'>음성인식으로 텍스트 입력하기</span>
                      </S.InputButton> */}
                    </S.SearchBarInputGroup>
                    {!semanticSearch && (
                      <S.SuggestedKeywords {...getMenuProps()}>
                        {isOpen &&
                          items
                            .filter((item) => !searchText || item.includes(searchText))
                            .map((item, index) => (
                              <S.SuggestedKeyword
                                key={item}
                                isFocused={highlightedIndex === index}
                                {...getItemProps({
                                  key: item,
                                  index,
                                  item,
                                  onClick: () => inputRef.current?.focus(),
                                })}
                              >
                                {item}
                              </S.SuggestedKeyword>
                            ))}
                      </S.SuggestedKeywords>
                    )}
                  </S.SearchBarInner>
                </S.SearchBar>
              )}
            </Downshift>
          </form>
        </div>
      </S.SearchBarWrapper>
      <S.OptionContainer>
        <div
          style={{
            marginLeft: '24px',
          }}
        >
          <OrSearchCheck isGray initialValue={orSearch} setChangedValue={setOrSearch} />
        </div>
        <div
          style={{
            marginLeft: '24px',
          }}
        >
          <SemanticSearchCheck
            isGray
            initialValue={semanticSearch}
            setChangedValue={setSemanticSearch}
          />
        </div>
      </S.OptionContainer>
    </S.Container>
  )
}
