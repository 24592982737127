import { createContext, useReducer, useState } from 'react'

export const CalendarContext = createContext<{
  isOpen: boolean
  handleOpenClose?: (state: boolean) => void
  rangeDate: (Date | null)[]
  handleRangeChange?: (dates: (Date | null)[]) => void
  singleDate: Date | null
  handleSingleChange?: (date: Date | null) => void
  mode?: string
  handleChangeMode?: (mode: string) => void
}>({
  isOpen: false,
  rangeDate: [new Date(), null],
  singleDate: new Date(),
})

export const context = CalendarContext

interface CalendarContextProps {
  children: React.ReactNode
}

export function Provider({ ...rest }: CalendarContextProps): JSX.Element {
  // ==================== calendar mode
  const [mode, setMode] = useState<string | undefined>(undefined)

  function handleChangeMode(m: string): void {
    setMode(m)
  }

  // ==================== calendar open/close
  const [isOpen, setIsOpen] = useState(false)

  function handleOpenClose(state: boolean): void {
    setIsOpen(state)
  }

  // ==================== single date select
  const [singleDate, setSingleDate] = useState<Date | null>(null)

  function handleSingleChange(date: Date | null): void {
    setSingleDate(date)
  }

  // ==================== range date Select
  const [rangeDate, setRangeDate] = useReducer(
    (prev: (Date | null)[], next: (Date | null)[]) => {
      return next
    },
    [new Date(), null],
  )

  function handleRangeChange(dates: (Date | null)[]): void {
    const [start, end] = dates

    setRangeDate([start, end])
  }

  return (
    <CalendarContext.Provider
      value={{
        isOpen,
        handleOpenClose,
        rangeDate,
        handleRangeChange,
        singleDate,
        handleSingleChange,
        mode,
        handleChangeMode,
      }}
      {...rest}
    />
  )
}
