import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

const Figure = styled.figure<{ $loading: boolean }>`
  ${({ $loading }) =>
    $loading &&
    css`
      width: 100%;
      height: ${Math.random() * 300}px;

      background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
      background-size: 250px;
      animation: skeleton 1.5s infinite linear;

      @keyframes skeleton {
        0% {
          background-position: -100px;
        }

        100% {
          background-position: 140px;
        }
      }
    `};
`

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderSrc: string
  src: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export function ProgressiveImg({ placeholderSrc, src, onClick, ...props }: Props) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // 이미지를 업데이트 합니다.
    const img = new Image()

    img.src = `${process.env.REACT_APP_BASE_URL}${src}`

    img.style.width = '180px'

    img.onload = () => {
      setImgSrc(`${process.env.REACT_APP_BASE_URL}${src}`)
      setLoading(false)
    }
  }, [src])

  return (
    <Figure $loading={loading} onClick={onClick}>
      {!loading && (
        <img
          {...{ src: imgSrc, ...props }}
          alt={props.alt || ''}
          className='image'
          height={loading ? 100 : 'initial'}
        />
      )}
    </Figure>
  )
}
