import styled from '@emotion/styled'

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.08);

  &:before {
    content: '';
    background-color: #fff;
    width: 100%;
    height: 1px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0;
    width: min(100%, 1200px);
  }

  .area_info {
    margin-top: 3px;
    li {
      position: relative;
      display: inline-block;

      :nth-of-type(n + 2) {
        margin-left: 18px;

        ::before {
          content: '';
          position: absolute;
          top: 48%;
          left: -9px;
          transform: translateY(-48%);
          border-radius: 50%;
          width: 2px;
          height: 2px;
          background-color: rgba(34, 34, 34, 0.7);
        }
      }

      a {
        display: inline-block;
        font-size: 1.1rem;
        line-height: 2.2rem;
        font-weight: 700;
        letter-spacing: -0.01em;
        color: rgba(34, 34, 34, 0.7);
      }
    }

    address {
      span {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6rem;
        color: rgba(34, 34, 34, 0.5);
      }

      .address_tel {
        margin-left: 9px;
      }

      .address_fax {
        margin-left: 12px;
      }
    }

    .txt_copy {
      margin-top: 5px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      letter-spacing: -0.04em;
      color: rgba(34, 34, 34, 0.5);
    }
  }

  .button_box {
    flex: 1;
    margin-left: 20px;

    button {
      border: 1px solid #bcbec9;
      border-radius: 30px;
      padding: 3px 15px;

      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: rgba(34, 34, 34, 0.5);
    }
  }

  .area_shortcut {
    margin-right: 4px;

    a {
      display: inline-block;
    }

    .icon_youtube {
      margin-left: 30px;
    }
  }
`

export const VersionPopupWrapper = styled.div`
  background-color: #fff;
  width: 400px;
  padding: 30px;

  .heading_box {
    padding-bottom: 18px;
    border-bottom: 1px solid #e1e1e1;

    .title {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.03em;

      color: #222222;
    }
  }

  .contents {
    margin-top: 20px;
  }

  .solution_box {
    span {
      display: block;
      color: #222222;
    }

    span:nth-of-type(1) {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.7rem;
      letter-spacing: -0.01em;
    }

    span:nth-of-type(2) {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.1rem;
    }
  }

  .compoents_box {
    margin-top: 20px;

    > span {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.7rem;
      letter-spacing: -0.01em;
      color: #222222;
    }

    ul {
      margin-top: 10px;
    }

    li {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.1rem;
      letter-spacing: -0.01em;
      color: #222222;

      &:before {
        content: '·';
        margin-right: 7px;
      }

      span + span {
        margin-left: 15px;
      }
    }
  }
`
