import { all } from 'redux-saga/effects'
import audiosSaga from './audiosSaga'
import documentsSaga from './documentsSaga'
import imagesSaga from './imagesSaga'
import searchSaga from './searchSaga'
import videosSaga from './videosSaga'

export default function* rootSaga() {
  yield all([searchSaga(), documentsSaga(), imagesSaga(), videosSaga(), audiosSaga()])
}
