import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const printStyle = `

@page {
    size: A4;
    margin: 0;
  }
  @media print {
    
    .search_result_inner {
      display:none;
    }

    html,
    body {
      width: 210mm;
      height: 297mm;
      margin: 0;
      padding: 0;
      background-color: #fff;
    }

    .wrap_popup {
      padding: 20mm;
      margin: 0 0 0 -20mm;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }

    .content_docList,
    .popup_thumb,
    .content_img,
    .content_media,
    figure,
    video {
      display: none;
    }

    .image_popup .popup_info{
      display: none;
    }
    
    .image_popup .popup_content{
      display: none;
    }
  }
`

export const PopupLayout = styled.div<{ $showContent?: boolean; $loading?: boolean }>`
  position: relative;
  width: 960px;
  background-color: #fff;

  .popup_content {
    position: relative;
    padding: ${({ $showContent }) => ($showContent ? '0 30px 71px' : '0 30px 53px')};
  }

  .popup_info {
    display: flex;
    justify-content: space-between;
    margin: 0 30px;
    padding: 20px 0 19px;
    border-bottom: solid 1px #e1e1e1;
  }

  .sentences_inner_hits {
    padding: 2rem;
    width: 240px;
    height: 405px;
    overflow-x: hidden;
    overflow-y: auto;
    border-bottom: solid 1px #e1e1e1;

    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2.4rem;
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: -0.01em;
      cursor: pointer;

      span.timestamp {
        display: inline-flex;
        padding: 0 0.7rem;
        height: 2.4rem;
        font-size: 1rem;
        color: #0f52ff;
        border: 1px solid #0652ff;
        border-radius: 1rem;
      }
    }

    p + p {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid #e1e1e1;
    }

    strong {
      font-weight: 700;
      color: #0f52ff;
    }
  }

  .info_detail {
    dl {
      display: flex;
    }

    div {
      display: flex;
      align-items: center;
    }

    div:nth-of-type(n + 2) {
      margin-left: 35px;
    }

    dt {
      display: inline-block;
      box-sizing: border-box;
      padding: 0 9px;
      border: solid 1px #e1e1e1;
      border-radius: 4px;
      height: 28px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: #666;
    }

    dd {
      display: inline-block;
      margin-left: 10px;
      span {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.01em;
      }
    }

    a {
      display: inline-block;
      padding: 0 10px;
    }

    a:hover {
      svg path {
        stroke: #0f52ff;
      }
    }
  }

  .info_cotrol {
    a {
      display: inline-block;
      padding: 0 9px;
      border: solid 1px #e1e1e1;
      border-radius: 4px;
      height: 28px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: -0.03em;
      color: #666;
      background-color: #f8f8f8;

      :nth-of-type(n + 2) {
        margin-left: 10px;
      }

      :hover {
        color: #0f52ff;
        svg path {
          fill: #0f52ff;
        }
      }
    }

    svg {
      margin-right: 5px;
      height: 27px;
    }

    button {
      margin-left: 10px;
      padding: 0 9px;
      border: solid 1px #e1e1e1;
      border-radius: 4px;
      height: 28px;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: -0.03em;
      color: #666;
      background-color: #f8f8f8;

      :hover {
        color: #0f52ff;
        svg path {
          fill: #0f52ff;
        }
      }
    }
  }

  .link_close {
    position: absolute;
    top: -39px;
    right: 10px;
  }

  .bth_viewMore {
    visibility: ${({ $showContent }) => ($showContent ? 'visible' : 'hidden')};
    position: absolute;
    left: 31px;
    bottom: 29px;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.01em;
  }

  .content_txt {
    padding: 0 11px;
    height: ${({ $showContent }) => ($showContent ? '267px' : 'auto')};
    overflow: hidden;

    h3 {
      padding: 30px 0 32px;
      font-size: 2rem;
      font-weight: bold;
      line-height: 29px;
    }

    p {
      font-size: 1.4rem;
      line-height: 160%;
      font-weight: 400;
      letter-spacing: -0.01em;

      b {
        font-weight: 700;
      }

      + p {
        padding-top: 21px;
      }
    }

    strong {
      font-weight: 700;
      color: #0f52ff;
    }
  }

  /* Document ----------------------- */
  .openAiSummury {
    font-size: 1.4rem;
    line-height: 160%;
    font-weight: 400;
    letter-spacing: -0.01em;

    button {
      display: flex;
      color: #0f52ff;
      margin-bottom: 1.4rem;

      svg {
        margin-right: 0.7rem;
        width: 2.4rem;
        height: 2.4rem;

        path {
          fill: #0f52ff;
        }
      }
    }

    p {
      display: block;
      margin-bottom: 1.4rem;
      padding: 1.2rem;
      border: 1px solid #0f52ff;
      border-radius: 0.7rem;
    }
  }
  .content_img {
    margin: 60px 0 7px;

    strong {
      display: block;
      padding: 0 11px 9px;
      border-bottom: solid 1px #e1e1e1;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.03em;
    }

    .box_table {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
      margin: 20px 10px 0;
    }

    .box_tableItem {
      width: 168px;
      height: 91px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .selectedImg {
      padding: 20px 10px;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .content_docList {
    padding: 35px 30px 43px;
    background-color: #f8f8fc;
  }

  /* Image -------------------------- */
  .popup_areaThumb {
    position: relative;
    button {
      position: absolute;

      svg {
        opacity: 0.3;
      }

      svg:hover {
        opacity: 1;
      }
    }

    .btn_navLeft {
      top: 50%;
      left: 21px;
      transform: translateY(-50%);
    }

    .btn_navRight {
      top: 50%;
      right: 21px;
      transform: translateY(-50%);
    }
  }

  .popup_thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 250px;
    background-color: #111;

    img {
      width: auto;
      max-width: 960px;
    }
  }

  /* Voice -------------------------- */
  .content_media {
    display: flex;
    align-items: strech;

    div.media-container {
      position: relative;
      min-height: 405px;
      background-color: #f1f3f4;
    }

    audio {
      position: absolute;
      bottom: 0;
      padding: 0 5px;
      width: 100%;
    }

    svg {
      position: absolute;
      top: calc(50% - 69px);
      left: calc(50% - 69px);
      path {
        stroke: #ccc;
      }
    }

    audio::-webkit-media-controls-play-button {
      margin-left: 4px;
      margin-bottom: 3px;
      transform: scale(1.5, 1.5);
    }

    audio::-webkit-media-controls-mute-button {
      transform: scale(1.5, 1.5);
    }
  }

  /* Document: 유사문서 클릭 시 보여지는 로딩 페이지 -------------------------- */
  ${({ $loading }) =>
    $loading &&
    css`
      .info_detail {
        dd {
          border-radius: 8px;
          width: 100px;
          height: 20px;
          background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
          background-size: 250px;
          animation: skeleton 1.5s infinite linear;
        }

        div:nth-of-type(1) dd {
          width: 180px;
          height: 24px;
        }

        div:nth-of-type(2) dd {
          width: 70px;
          height: 24px;
        }

        div:nth-of-type(n + 2) {
          margin-left: 48px;
        }

        @keyframes skeleton {
          0% {
            background-position: -100px;
          }

          100% {
            background-position: 140px;
          }
        }
      }
    `}
`
