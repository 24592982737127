import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'

import audios from './audios'
import documents from './documents'
import images from './images'
import rootSaga from './saga'
import search from './search'
import videos from './videos'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
})

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
  search: search.reducer,
  documents: documents.reducer,
  images: images.reducer,
  videos: videos.reducer,
  audios: audios.reducer,
  router: routerReducer,
})

const store = configureStore({
  reducer: reducer,
  middleware: [sagaMiddleware, routerMiddleware],
  devTools: process.env.NODE_ENV !== 'production' ?? false,
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const history = createReduxHistory(store)

export default store
