'use client'

import { useContext, useLayoutEffect } from 'react'
import RDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { ko } from 'date-fns/locale'

import { CalendarHeader } from './CalendarHeader'
import { CalendarContext } from './context'
import styled from '@emotion/styled'

type CalendarProps = ReactDatePickerProps & {
  onCloseCalendar?: () => void
}

export function DatePicker({
  selected,
  onCloseCalendar,
  onChange,
  selectsRange,
  shouldCloseOnSelect = true,
  ...calendarProps
}: CalendarProps): JSX.Element {
  const { isOpen, handleOpenClose, handleChangeMode } = useContext(CalendarContext)

  useLayoutEffect(() => {
    handleChangeMode && handleChangeMode('single')
  }, [handleChangeMode])

  return (
    <Container>
      <RDatePicker
        {...calendarProps}
        locale={ko}
        onCalendarClose={() => {
          handleOpenClose?.(false)
        }}
        onCalendarOpen={() => {
          handleOpenClose?.(true)
        }}
        onChange={(date, e) => {
          onChange(date, e)
          shouldCloseOnSelect && handleOpenClose?.(false)

          onCloseCalendar?.()
        }}
        onInputClick={() => {
          handleOpenClose?.(true)
        }}
        open={isOpen}
        renderCustomHeader={(props) => <CalendarHeader {...{ ...props, selectsRange }} />}
        selected={selected}
        selectsRange={selectsRange}
        shouldCloseOnSelect={shouldCloseOnSelect}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode='select'
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.6rem;
  overflow: hidden;
`
