export function convertDuration(time: number) {
  if (time === 0) return '--:--'

  const minutes = Math.floor(time / 60)

  const seconds = time - 60 * minutes
  const newSeconds = seconds < 10 ? `0${seconds}` : seconds

  return `0${minutes}:${newSeconds}`
}
