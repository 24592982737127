import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { documentsDataType, documentsType } from '@/types/documentsType'

interface initialStateType {
  loading: boolean
  error: unknown
  allDocumentsDataCount: number
  documents: documentsDataType[] | null
  pagingNumber: number
}

const initialState: initialStateType = {
  loading: false,
  error: null,
  allDocumentsDataCount: 0,
  documents: null,
  pagingNumber: 0,
}

const documents = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    getDocumentsData: (state, action: PayloadAction<documentsType>) => {
      const { meta, data } = action.payload

      state.allDocumentsDataCount = meta.count
      state.documents = data
      state.pagingNumber = 1

      return state
    },
    getNextDocumentsData: (
      state,
      action: PayloadAction<{ response: documentsType; paging: number }>,
    ) => {
      const {
        response: { data },
        paging,
      } = action.payload

      state.documents = data
      state.pagingNumber = paging
    },

    reset: (state, action: PayloadAction<keyof initialStateType>) => {
      const id = action.payload

      if (id === 'documents') state[id] = initialState[id]

      return state
    },
    error: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload

      return state
    },
  },
})

export const documentsActions = { ...documents.actions }

export default documents
