import axios from './index'

export default class SearchService {
  /** 통합 검색  */
  static async getSearchES(params: {
    keyword: string
    s_event_date?: string
    e_event_date?: string
    paging?: number
    countPerPAge?: number
    semantic: boolean
    or_operator: boolean
  }) {
    return await axios.get('/search/', { params })
  }

  // 자동완성
  static async getSearchAC(params: { keyword: string }) {
    const data = await (await axios.get('/search/ac', { params })).data
    const keywords = data.data
    return Array.isArray(keywords) ? keywords : []
  }

  // 연관 검색어
  static async getRelatedWord(params: { keyword: string }) {
    return await axios.get('/search/related_word', { params })
  }

  // 인기 검색어
  static async getPopularWord(params: { [key: string]: string | null }) {
    return await axios.get('/search/popular', { params })
  }
}
