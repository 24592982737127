import { imagesDataType } from '@/types/imagesType'
import React, { forwardRef } from 'react'

import * as S from './ImgItem.styled'

export interface ImgItemProps extends React.HTMLAttributes<HTMLLIElement> {
  dataItem: imagesDataType
}

export const ImgItem = forwardRef<HTMLLIElement, ImgItemProps>(
  ({ dataItem, ...restProps }: ImgItemProps, ref) => {
    const { thumbnail } = dataItem

    return (
      <S.ImgItem ref={ref} {...restProps}>
        <img src={`${process.env.REACT_APP_BASE_URL}${thumbnail}`} alt='' />
      </S.ImgItem>
    )
  },
)

ImgItem.displayName = 'ImgItem'
