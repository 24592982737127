import { useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

type ImageCropProps = {
  imgSrc: string
  setCropFileUrl: React.Dispatch<React.SetStateAction<string | null>>
}

export default function ImageCrop({ imgSrc, setCropFileUrl }: ImageCropProps) {
  const imgRef = useRef<HTMLImageElement>(null)

  const [crop, setCrop] = useState<Crop>()
  const [pending, setPending] = useState(true)

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget

    setCrop(centerAspectCrop(width, height, width / height))
  }

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      canvasPreview(imgRef.current, completedCrop, setCropFileUrl)
    }
  }, [completedCrop, setCropFileUrl])

  return (
    <>
      <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => {
          if (pending) {
            setPending(false)

            return
          }

          setCompletedCrop(c)
        }}
      >
        <img ref={imgRef} alt='Crop me' src={imgSrc} onLoad={onImageLoad} />
      </ReactCrop>
    </>
  )
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
        height: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

// const TO_RADIANS = Math.PI / 180

async function canvasPreview(
  image: HTMLImageElement,
  crop: PixelCrop,
  // scale = 1,
  // rotate = 0,
  setCropFileUrl: React.Dispatch<React.SetStateAction<string | null>>,
) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  ctx.save()
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )

  ctx.restore()

  canvas.toBlob((blob) => {
    console.log(blob)
  })
  const base64Image = await canvas.toDataURL()

  setCropFileUrl(base64Image)
}
