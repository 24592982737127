import React, { forwardRef, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import parse from 'html-react-parser'

import * as S from './DocItem.styled'

import { documentsDataType } from '@/types/documentsType'
import { hasPopupSource, keywordHighlighting } from '@/functions'

export interface DocItemProps extends React.HTMLAttributes<HTMLLIElement> {
  dataItem: documentsDataType
}

export const DocItem = forwardRef<HTMLLIElement, DocItemProps>(
  ({ dataItem, ...restProps }: DocItemProps, ref) => {
    const [searchParams] = useSearchParams()

    const keyword = useMemo(() => searchParams.get('keyword'), [searchParams])
    const {
      title,
      contents,
      date,
      thumbnail,
      col_dt: colDt,
      data_src_l_cd: dataSrcLCd,
      data_src_m_cd: dataSrcMCd,
      data_src_s_cd: dataSrcSCd,
      score: score,
      page_no: page_no,
    } = dataItem

    console.log('page_no : ', page_no)

    if (keyword == null) return null

    return (
      <S.DocItem ref={ref} $hasImg={!!thumbnail} {...restProps}>
        <div className='doc_info'>
          <p role='doc-subtitle'>
            {parse(
              keywordHighlighting(
                title ? title : contents !== '' ? `${contents.slice(0, 20)}...` : '-',
                keyword,
              ),
            )}
          </p>

          {/* <p>{contents !== '' ? parse(keywordHighlighting(contents, keyword)) : '-'}</p> */}
          <p>
            {contents
              ? keyword
                ? parse(keywordHighlighting(contents, keyword))
                : parse(contents)
              : '-'}
          </p>

          <dl className='additional_info'>
            <div>
              <dt>게시일: </dt>
              <dd>
                <span>
                  <time dateTime={date}>
                    {date ? date.slice(0, -9).replaceAll('-', '. ') : '-'}
                  </time>
                </span>
              </dd>
            </div>
            <div>
              <dt>수집일:</dt>
              <dd>
                <span>
                  <time dateTime={colDt}>
                    {colDt ? colDt.slice(0, -9).replaceAll('-', '. ') : '-'}
                  </time>
                </span>
              </dd>
            </div>
            <div>
              <dt>출처:</dt>
              <dd>
                <span>{hasPopupSource(dataSrcLCd, dataSrcMCd, dataSrcSCd)}</span>
              </dd>
            </div>
            {score && (
              <div>
                <dt>유사도:</dt>
                <dd>
                  <span>{score}</span>
                </dd>
              </div>
            )}
            {page_no !== null && page_no !== undefined && page_no !=='' && (
              <div>
                <dt>페이지:</dt>
                <dd>
                  <span>{page_no}</span>
                </dd>
              </div>
            )}
          </dl>
        </div>
        {thumbnail && (
          <div className='doc_thumb'>
            <img
              src={`${process.env.REACT_APP_BASE_URL}${thumbnail}`}
              alt=''
              width={100}
              height={100}
            />
          </div>
        )}
      </S.DocItem>
    )
  },
)

DocItem.displayName = 'DocItem'
